@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

.group-detail-page {
    padding: 0 30px;
    height: 100%;
    position: relative;
    
    @include media-breakpoint(md, max) {
        padding: 0 10px;
    }

    .ant-breadcrumb {
        a, .ant-breadcrumb-separator {
            color: $color-grey-light;
        }

        >span {
            &:last-child {
                a {
                    color: $color-grey-light !important;
                }
            }
        }
    }

    .header {
        padding: 24px 0;
    }

    @include media-breakpoint(lg, min) {
        .header {        
            display: flex;
            justify-content: space-between;            
        }
    }  
    
    @include media-breakpoint(md, max) {
        .header {

            .actions {
                // padding: 24px 0;
                display: flex;

                .ant-btn {
                    width: 100%
                }
            }
        }
    }

    .main {
        color: #fff; 

        @include media-breakpoint(lg, min) {
            height: 90%;
        }

        .tabs-container {
            height: 100%;

            .ant-tabs-card {
                height: 100%;

                .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                        .ant-tabs-nav-list {
                            .ant-tabs-tab {
                                background-color: transparent;
                                border: none;
                                color: #fff;
                                width: 150px;
                                justify-content: center;

                                &-active {
                                    background-color: var(--color-primary-dark2);
                                    border-top-left-radius: 20px;
                                    border-top-right-radius: 20px;

                                    .ant-tabs-tab-btn {
                                        color: #fff;
                                    }
                                }
                            }

                            .ant-tabs-tab:not(:first-child) {
                                &.ant-tabs-tab-active + .ant-tabs-content {
                                    border-top-left-radius: 0;
                                }                                
                            }
                        }
                    }
                }

                .ant-tabs-content {
                    margin-top: -16px;        
                    height: 100%;

                    >.ant-tabs-tabpane {
                        padding: 28px;
                        color: #fff;
                        border-radius: 10px;                        
                        overflow-y: scroll;
                        overflow-x: hidden;
                        @include scrollbar(transparent, var(--color-primary-dark3));    

                        @include media-breakpoint(lg, min) {
                            background-color: var(--color-primary-dark2);
                        }
                        
                        &:first-child {
                            border-top-left-radius: 0;
                        }

                        p {
                            margin-bottom: 58px;
                        }
                    }
                }

                >.ant-tabs-nav::before {
                    display: none;
                }
            }

            & [data-theme='compact'] {
                > .ant-tabs-card .ant-tabs-tab {
                    background: transparent;
                    border-color: transparent;
                }
            }
        }



        .ant-tree {
            background: transparent;
            color: #fff;

            .ant-tree-treenode-selected {
                &:before {
                    background: transparent;
                }
            }

            .ant-tree-node-content-wrapper {
                width: 100%;

                &.ant-tree-node-content-wrapper-normal {
                    display: flex;
                    justify-content: stretch;

                    .ant-tree-iconEle {
                        color: $color-success;
                    }

                    .ant-tree-title {
                        flex: 2;
                    }
                }

                &:not(.ant-tree-node-content-wrapper-normal) {
                    .ant-tree-title {
                        font-weight: 500;
                    }
                }

                &:hover {
                    background: transparent;
                }
            }

            .ant-tree-treenode {
                width: 100%;
                padding-bottom: 20px;

                &:hover {
                    &:before {
                        background: transparent;
                    }
                }
            }
        }

        .ant-tree-switcher {
            display: none;
        }

        .custom-statistics {
            width: 180px;
            height: 88px;
            border-radius: 6px;
            padding: 10px 20px;
            margin-right: 20px;

            .value {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            @include media-breakpoint(md, max) {
                padding: 8px 15px;
                margin-right: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .tile-view {
            padding-right: 10px;

            @include media-breakpoint(md, max) {
                padding-right: 0;
            }

            .ant-card {
                border: none;
                color: #fff;
                background-color: var(--color-primary-dark3);
            }
        }

        .ant-table {
            background-color: var(--color-primary-dark3);
            color: #fff;

            .ant-table-thead {
                tr {
                    th {
                        background-color: var(--color-primary-dark3);
                        color: #fff;
                        border-bottom: none;
                    }

                    &.ant-table-row-hover:not(.ant-table-expanded-row),
                    &:hover:not(.ant-table-expanded-row) {
                        td {
                            background: unset; //Change the existing color to `unset`
                        }
                    }
                }
            }

            .ant-table-tbody{
                tr {
                    &.ant-table-row-hover:not(.ant-table-expanded-row),
                    &:hover:not(.ant-table-expanded-row) {
                        td {
                            background: unset; //Change the existing color to `unset`
                        }
                    }
                    td {
                        border: none;
                        a {
                            color: #fff;
                        }
                        
                        &.ant-table-column-sort {
                            background-color: var(--color-primary-dark3);
                        }
                    }

                }
            }

            .ant-table-column-sorter {
                margin-left: 0px;
            }
        }

        .slider-toggle {
            height: 36px;
            border-radius: 40px;
            background-color: #6a8dac;
            border: 1px solid #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            font-size: 12px;

            .toggle-item {
                width: 100%;
                text-align: center;
                height: 34px;
                line-height: 36px;
                
                &.active {
                    background-color: $color-accent1;
                    color: $color-button-text;
                    border-radius: 40px;
                }
            }
        }
    }

    .platforms-filter {
        padding: 0;
                
        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
        }

        .middle-row {
            // border-bottom: 1px solid #fff;
            padding-bottom: 20px;

            @include media-breakpoint(md, max) {
                margin-top: 20px;
            }

            .toggle-btns {
                .ant-btn {
                    background: #000;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    padding: 10px 20px;
                    height: 40px;
                }
            }

            .ant-select {
                font-size: 14px;

                .ant-select-selector {
                    border-radius: 4px;
                }

                .ant-select-arrow {
                    color: $color-grey-dark;
                    font-size: 14px;
                }                

                .ant-select-selector {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    .ant-select-selection-placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }

                @include media-breakpoint(md, max) {
                    &.sorter {
                        .ant-select-selector {
                            color: #fff;
                        }
                    }
                }
            }

            .ant-input-affix-wrapper {
                color: $color-grey-dark;
                background-color: $color-grey-light;

                .ant-input {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    &::placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }
            }
        }

        .ant-tabs-top {
            .ant-tabs-nav::before {
                border-bottom: none;
            }
        }

        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                color: #fff
            }
        }

        .ant-tabs-ink-bar {
            background: $color-accent1;
            color: $color-button-text;
        }
    }

    .data-more-actions-drawer {

        .ant-drawer-mask {
            background-color: rgba(0, 0, 0, 0.45);
        }

        .ant-drawer-content {
            .ant-drawer-wrapper-body {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                background-color: #fff;

                &:after {
                    height: 0;
                }

                .data-more-actions {
                    padding: 0 20px;
                    color: $color-grey-dark;

                    div {
                        padding: 20px;

                        &:not(:last-child) {
                            border-bottom: 1px solid $color-grey-light;
                        }
                    }
                }
            }
        }
    }
}