@import '../../assets/variables.scss';

.ant-collapse {
    margin-top: 16px;
    background-color: var(--color-primary-dark2);
    border: none;
    border-radius: 10px;

    .ant-collapse-item {
        border: none;

        .ant-collapse-header {
            padding: 0;

            .ant-col {
                padding: 16px 40px;                    
                color: #fff;

                &.left {
                    border-top-left-radius: 10px;

                    &:not(.active) {
                      border-radius: 10px;  
                      transition: border-radius cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                }

                .title {
                    letter-spacing: 1px;
                }
            }
        }

        .ant-collapse-content {
            background-color: var(--color-primary-dark2);
            border: none;
            color: #fff;               
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .ant-collapse-content-box {
                padding: 0;

                .ant-row {    

                    .ant-col {
                        padding: 0;

                        &:first-child {
                            padding-left: 30px;

                            div {
                                padding-top: 10px;
                            }
                        }

                        div {                             
                            border-top: 1px solid #666666;
                        }

                        &:not(:first-child) {
                            div {   
                                padding: 10px 30px;             
                            }
                        }

                        &:last-child {
                            padding-right: 30px;
                        }
                    }  
                }
            }
        }

        &.bg-primary {
            .left {
                background-color: $color-primary;
            }
    
            .ant-collapse-content {
                .ant-row {
                    .ant-col:first-child {
                        background-color: var(--color-primary-30);
                    }
                }
            }
        }
    
        &.bg-success {
            .left {
                background-color: $color-success;
            }
    
            .ant-collapse-content {
                .ant-row {
                    .ant-col:first-child {
                        background-color: var(--color-success-30);
                    }
                }
            }
        }

        &.bg-accent {
            .left {
                background-color: $color-accent1;
                color: $color-button-text;
            }
    
            .ant-collapse-content {
                .ant-row {
                    .ant-col:first-child {
                        background-color: var(--color-accent1-30);
                    }
                }
            }
        }
    }
}