@import '../../assets/variables.scss';

.ant-modal-wrap {
    z-index: 1050;
}

.old-download-wrapper {
    padding: 15px;
    
    .ant-btn {
        &.download {
            color: $color-accent1;
            border-radius: 4px;
            stroke-width: 2;
            font-size: 14px;
            font-weight: 500;
            
            &.link {
                // font-size: 16px;
                height: 40px;

                span {
                    svg {
                        margin: 0 5px;
                    }
                    
                    margin-top: 12px;
                }
            }
            
            &:hover {
                border-color: $color-accent1;
            }
        }
    }

    .ant-row {
        margin-top: 15px;
    }

    .csv-warning {
        path {
            color: $color-danger;
        }
    }

    .anticon-info-circle {
        margin-left: 5px;
    }
    
    .erddap-explainer {
        margin-top: 10px;
    }
}