@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.data-set-list {
    padding: 0 40px;
    height: 100%;
    position: relative;

    @include media-breakpoint(md, max) {
        padding: 0 10px;
    }

    h2 {
        color: $color-white;
        font-family: 'PulpDisplay-SemiBold', serif;
    }

    .ant-btn.filter-btn {
        border-radius: 15px;
        color: $color-white;
        font-size: 14px;
        font-weight: bolder;
        border: 1px solid $color-accent1;
        padding: 4px 20px 10px;
        margin: 0 10px;

        @include media-breakpoint(md, max) {
            width: 100%;
            margin: 5px 0;
        }

        &.search {
            border-radius: unset;
            background-color: $color-accent1;

            @include media-breakpoint(md, min) {
                &:hover {
                        color: $color-white;
                }
            }
        }

        &.active {
            background-color: $color-accent1;
        }

        @include media-breakpoint(md, min) {
            &:hover {
                background-color: $color-accent1;
                color: $color-white;
            }
        }
    }

    .main {
        margin: 20px 0;
        position: relative;

        .dataset-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            grid-gap: 20px;
        }

        .page-selector {
            display: flex;
            color: $color-white;
            font-weight: bolder;
            margin: 10px 0;

            .page-text {
                span {
                    margin: 0 2px;
                    vertical-align: text-top;
                }
            }
        }

        .results {
            color: $color-white;
            font-size: 16px;
            font-weight: bolder;
            margin-left: 20px;
            span {
                margin: 0 2px;
            }
        }
    }

    .filter-wrapper {
        border-bottom: 1px solid $color-white;
        padding: 15px 0 20px 0;

        @include media-breakpoint(md, max) {
            padding: 0 10px 10px;
        }
    }
}