@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

.map-controls {
  .favorites-filter-button,
  .share-button {
    background-color: rgba(3, 31, 49, 0.70);
    border: none;
    margin-top: 20px;
    height: 40px;
    width: 40px;
    padding: 7px 8px 8px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    z-index: 10;

    .anticon {
      margin-bottom: -2px;
      svg {
        width: 23px;
        height: 23px;
      }
    }
    .hover-text {
      display: none;
      color: white;
    }

    &:hover {
      background-color: rgba(3, 31, 49, 0.70);
      width: auto;
      .hover-text {
        display: inline-block;
      }
    }

    &.active, &:focus {
      background-color: rgba(3, 31, 49, 0.70);
    }

    &:hover {
      &:focus {
        background-color: rgba(3, 31, 49, 0.70);
      }
    }
  }

  .share-button {
    .anticon {
      margin-bottom: -6px;
      margin-left: 1px;
    }
  }

  .seagull-tag {
    > .anticon svg {
      width: 60px;
      margin-bottom: -5px;
      g {
        fill: #444 !important;
      }
    }
  }

  .seagull-tag.add-filter {
    > .anticon svg {
      width: 60px;
      margin-bottom: -5px;
      g {
        fill: #225977 !important;
      }
    }
    .ant-tag-close-icon {
      color: #225977 !important;
    }
    color: #225977 !important;
  }

  .nest-tag {
    > .anticon svg {
      g path {
        fill: #444 !important;
      }
    }
  }

  .seagull-tag.add-filter {
    background-color: #dbe1e7 !important;

    .ant-tag-close-icon svg {
      transform: rotate(45deg);
    }
  }
}
