@import '../../assets/variables.scss';

.ant-modal-wrap {
  z-index: 1050;
}

.ant-tooltip-inner {
  background-color: var(--color-header);
}

.download-wrapper {
  padding: 0 15px;
  .bodyText {
    font-size: 16px;

    .subHeading {
      font-size: 19px;
      color: white;
      font-family: var(--seagull-heading-font);
    }
  }

  .ant-btn {
    &.download {
      color: $color-accent1;
      border-radius: 4px;
      stroke-width: 2;
      font-size: 14px;
      font-weight: 500;

      &.link {
        // font-size: 16px;
        height: 40px;

        span {
          svg {
            margin: 0 5px;
          }

          margin-top: 10px;
        }
      }

      &:hover {
        border-color: $color-accent1;
      }
    }
  }

  .ant-row {
    margin-top: 15px;
  }

  .csv-warning {
    path {
      color: $color-danger;
    }
  }

  .anticon-info-circle {
    margin-left: 5px;
  }

  .erddap-explainer {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .download-data {
    .ant-modal-content {
      width: 100vw;
      translate: -8px 0;
      height: 100vh;
      overflow-y: scroll !important;
      background-color: rgb(7, 74, 116) !important;

    }

    .viewButton {
      width: 100%;
    }

    .ant-modal {
      width: 50% !important;
      height: 100vh !important;
      top: 0 !important;
      left: 0 !important;
      padding: 0;
      max-width: none;
    }

    .ant-modal-body {
      height: 100%;
    }
  }
}