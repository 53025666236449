@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

html {
    overflow:hidden;
}

.console-detail {
    height: 100%;

    @include media-breakpoint(md, min) {
        padding: 0 20px;
    }

    .favorite-btn {
        border-radius: 4px;
        border: none;
    }

    .add-to-graph {
        text-decoration: underline;
        visibility: hidden;
        font-size: 12px;
        color: var(--color-accent1);
    }

    .desk-pin:hover {
        border-style: solid;

        & + .parameter + .add-to-graph {
            visibility: visible;
        }
    }

    .parameter-card {
        box-sizing: border-box;
        .ant-card-extra {
            padding-bottom: 0;
        }
        color: #fff;
    }

    .parameter-list {
        .parameter-list-inner {
            flex: 1;
            border-radius: 10px;

            @include media-breakpoint(md, min) {
                overflow: hidden;
            }

            .parameter-list-detail,
            .parameter-list-sidebar {
                overflow-y: scroll;
                position: relative;
                @include scrollbar(transparent, var(--color-primary-dark3));
            }

            .parameter-list-sidebar {
                .notifications {
                    .title {
                        padding-bottom: 15px;

                        .total-count {
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }

                    .ant-alert {
                        padding: 8px 10px;
                    }

                    .actions {
                        padding-bottom: 30px;

                        a {
                            color: #fff;
                            text-decoration: underline;
                            font-weight: 500;
                        }
                    }
                }

                .webcam-feed {
                    margin-top: 20px;

                    .video-js {
                        border-radius: 6px;

                        .vjs-tech {
                            border-radius: 6px;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                .weather-container {
                    border-radius: 6px;
                    padding: 10px;
                    margin-top: 20px;
                    background-color: #000000;

                    .title {
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .top {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 36px;

                        label {
                            margin-left: 10%;
                        }
                    }

                    .item {
                        margin: 20px 0;

                        .day {
                            text-transform: capitalize;
                        }

                        .max-temp {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;

                            label {
                                color: $color-accent1;
                            }
                        }

                        .min-temp {
                            text-align: center;
                            color: #3882b6;
                        }
                    }
                }

                .partner-container {
                    border-radius: 6px;
                    margin-top: 20px;

                    .images {
                        display: flex;
                        flex-direction: row;
                        // justify-content: space-between;
                        flex-wrap: wrap;
                        width: 100%;
                        // padding: 4px;

                        .image {
                            padding: 10px;
                            flex: 0 1 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .parameter-detail {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        @include media-breakpoint(sm, min) {
            height: 100%;
            max-height: 100%;
        }

        .period-menu {
            background: none;
            border: none;
            color: #fff;
            line-height: 30px;

            .ant-menu-item-active,
            .ant-menu-item-selected {
                color: #fff;
                border-color: $color-accent1;
            }

            & .ant-menu-item-disabled {
                color: rgba(255, 255, 255, 0.6) !important;
            }
        }

        .custom-date-filter {
            background-color: $color-primary;
            border-radius: 20px;
            border: none;
            color: #fff;
            max-width: 200px;

            @include media-breakpoint(md, max) {
                margin-top: 10px;
                width: 100%;
            }

            input {
                color: #fff;
            }

            .ant-picker-range-separator {
                padding-left: 0;
            }
        }

        .ant-btn {
            width: 40px;
            height: 40px;
            border-radius: 4px;
        }

        &-main, &-others {
            @include scrollbar(transparent, var(--color-primary-dark3));
        }

        &-main {
            .parameter-card {
                overflow: hidden;

                .depths-toolbar {
                    background-color: #000000;
                    font-size: 12px;
                    color: #fff;
                    padding: 10px;
                    position: relative;
                    @include scrollbar(transparent, var(--color-primary-dark3));

                    div {
                        cursor: pointer;
                        div {
                            svg {
                                g {
                                    color:#fff
                                }
                            }
                        }
                    }

                    > div:not(:first-child) {
                        margin-bottom: 30px;
                    }

                    > div:first-child {
                        margin-bottom: 24px;
                    }
                }
            }
        }

        &-others {
            background-color: #031f31;
            padding: 20px;

            @include media-breakpoint(md, max) {
                background: none;
                padding: 0;
            }
        }

        .featured-parameter {
            border: none;
            border-radius: 15px;
            padding-bottom: 0;
            background-color: inherit;
            color: #fff;
            margin-top: 10px;
            margin-bottom: 10px;

            @include media-breakpoint(md, max) {
                background-color: #052a4c;

                .ant-card-body {
                    padding: 10px;
                }
            }
        }
    }

    .ant-breadcrumb {
        margin-top: 5px;
        margin-bottom: 5px;

        a {
            color: $color-grey-light
        }

        span:last-child {
            a {
                color: $color-grey-light
            }
        }

        .ant-breadcrumb-separator {
            color: $color-grey-light
        }
    }

    .detail-switch {
        width: 100%;
        min-width: 94px;
        height: 36px;
        margin-bottom: 30px;

        @include media-breakpoint(md, max) {
            margin-bottom: 15px;
        }
    }

    .create-alert-drawer {
        .ant-drawer-content {
            padding-top: 4.7rem;
        }
    }

    .obs-dataset-summary {
        color: #fff;
        border-radius: 10px;
        padding: 20px;
        padding-bottom: 7px; // offset padding-bottom of p elements
        background-color: rgba(255, 255, 255, .12);
        p {
            color: #fff;
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.ant-picker-panels {
    .ant-picker-panel {
        background-color: #000000;

        .ant-picker-header {
            color: $color-grey-default;

            button {
                color: $color-grey-default;
            }
        }
        .ant-picker-content {
            th {
                color: $color-grey-default;
            }

            .ant-picker-cell {
                &:hover {
                    &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
                        .ant-picker-cell-inner {
                            background-color: $color-primary;
                        }
                    }
                }
                .ant-picker-cell-inner {
                    height: 40px;
                    line-height: 40px;
                }

                &:before {
                    height: 40px;
                }
            }

            .ant-picker-cell-disabled {
                .ant-picker-cell-inner {
                    color: $color-grey-default;
                }

                &:before {
                    background-color: #000000;
                }
            }

            .ant-picker-cell-in-view {
                color: rgba(255, 255, 255, 0.85);

                &.ant-picker-cell-in-range {
                    &:before {
                        background-color: $color-primary;
                    }
                }
                &.ant-picker-cell-range-start,
                &.ant-picker-cell-range-end {
                    .ant-picker-cell-inner {
                        background-color: $color-accent3;
                        min-width: 40px;
                        border-radius: 50%;

                        &:before {
                            border: none;
                        }
                    }
                }

                &.ant-picker-cell-range-start {
                    &:not(.ant-picker-cell-range-start-single) {
                        &:before {
                            background-color: $color-primary;
                        }
                    }
                }

                &.ant-picker-cell-range-end {
                    &:not(.ant-picker-cell-range-end-single) {
                        &:before {
                            background-color: $color-primary;
                        }
                    }
                }
            }
        }

        &:nth-child(2) {
            display: none;
        }
    }
}

.custom-tooltip {
    &-container {
        position: absolute;
        display: inline-block;
        border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    }

    &-text {
        width: 300px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
}
