@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.search-results {
    padding: 0 40px;
    height: 100%;
    position: relative;

    h2 {
        color: $color-white;
    }

    @include media-breakpoint(md, max) {
        padding: 20px 10px;
    }

    .filter-wrapper {
        display: flex;
        padding: 0 40px;

        @include media-breakpoint(md, max) {
            padding: 0 10px;
        }

        .ant-input-affix-wrapper {
            border-radius: 20px;
            padding: 10px 15px;
        }

        .search-filter {
            color: $color-white;
            font-family: 'PulpDisplay-SemiBold', serif;
            font-size: 16px;
            font-weight: 500;
            // padding: 0 3vw 0px;

            &.active {
                span {
                    border-bottom: 3px solid $color-accent1;
                }
            }
        }

        .filter-buttons {
            display: flex;
            justify-content: space-evenly;
            
            @include media-breakpoint(md, max) {
                padding-left: 0;
                overflow-x: scroll;
                overflow-y: hidden;
            }
        }
    }

    .results-wrapper {
        font-family: 'PulpDisplay-SemiBold', serif;

        .ant-card.search-results-card {
            .ant-card-body {
                @include media-breakpoint(md, max) {
                    padding: 10px;
                }
            }

            background-color: var(--color-primary-dark2);
            border: none;
            color: $color-white;
            border-radius: 20px;
            margin: 10px 0;

            .icon {
                color: $color-primary;
                
                svg {
                    font-size: 40px;
                    max-width: 100%;

                    @include media-breakpoint(md, max) {
                        height: 35px;
                    }
                }

                @include media-breakpoint(md, max) {

                    .status {
                        color: $color-white;
                        font-size: 14px;

                        svg {
                            height: 25px; 
                            width: 25px;      
                        }
                    }
                }
            }

            .info {
                .title {
                    font-size: 19px;
                }

                @include media-breakpoint(md, max) {
                    margin-top: 5px;
                }
            }

            .links {
                .link {
                    background-color: $color-black;
                    border-radius: 10px;
                    padding: 10px;
                    text-align: center;
                    margin-bottom: 10px;

                    a {
                        color: $color-white;
                    }
                }

                @include media-breakpoint(md, max) {
                    margin-top: 10px;
                }
            }
        }

        .platform-data {
            margin-top: 30px;

            @include media-breakpoint(md, max) {
                margin-top: 15px;
            }
        }

        .location-data {
            margin-top: 30px;

            .dataset-card-wrapper {
                margin: 10px 0;
            }

            @include media-breakpoint(md, max) {
                margin-top: 15px;
            }
        }

        .nf-data {
            margin-top: 30px;

            @include media-breakpoint(md, max) {
                margin-top: 15px;
            }

            .data {
                .dataset-card-wrapper {
                    margin: 10px 0;
                }
            }

            .data-pagination {
                display: flex;

                .page-selector {
                    display: flex;
                    color: $color-white;
                    font-weight: bolder;
                    margin: 10px 0;
                    
                    .page-text {
                        span {
                            margin: 0 2px;
                            vertical-align: text-top;
                        }
                    }
                    
                    .ant-btn {
                        border-radius: 15px;
                        color: $color-white;
                        font-size: 14px;
                        font-weight: bolder;
                        border: 1px solid $color-accent1;
                        padding: 4px 20px 10px;
                        margin: 0 10px;
                        
                        &.search {
                            border-radius: unset;
                            background-color: $color-accent1;
                            
                            &:hover {
                                color: $color-white;
                            }
                        }
                        
                        &.active {
                            background-color: $color-accent1;
                        }
                        
                        &:hover {
                            background-color: $color-accent1;
                            color: $color-white;
                        }
                    }
                }
                
                .results {
                    align-self: center;
                    color: $color-white;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-left: 20px;
                    span {
                        margin: 0 2px;
                    }
                }
            }
        }
    }
}