.confirm-leave-modal {
  .ant-modal-title {
    font-size: 20px;
  }

  .seagull-icon {
    width: 35px;
    height: 35px;
    margin: 0 -3px -7px -3px;
  }

  .ant-modal-footer {
    text-align: center; 
  }
}