@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

.group-create-page {
    padding: 30px;
    display: flex;
    flex-direction: column;
    // height: 130%;

    @include media-breakpoint(md, max) {
        padding: 30px 10px;
    }

    .header {
        display: flex;
        color: #fff;
        align-items: center;
        margin-bottom: 20px;

        a, span {
            color: #fff;
            font-size: 19px;
        }

        .ant-breadcrumb {
            margin-left: 20px;
        }
    }

    .ant-row {
        overflow: hidden;
        height: 100%;

        .ant-col {
            overflow-y: scroll;
            overflow-x: hidden;
            @include scrollbar(transparent, $color-grey-dark);

            // background-color: #fff;
            // border-radius: 4px;
        }
    }

    .ant-card {
        border-radius: 6px;    
        color: $color-grey-dark;

        .heading {
            color: $color-grey-default;
            margin-bottom: 35px;

            @include media-breakpoint(md, max) {
                margin-bottom: 24px;
            }
        }

        &.left-content {
            // height: 100%;

            .ant-card-body {
                padding: 16px 120px 16px 20px;

                @include media-breakpoint(md, max) {
                    padding: 18px;
                }
            
                .skip-step {
                    white-space: nowrap;
                    color: $color-accent1;
                }

                .heading-help {
                    margin-bottom: 35px;
                }

                .ant-btn {
                    border-radius: 4px;
                }

                .ant-form-item {
                    padding-bottom: 30px;

                    .form-list-item {
                        .ant-form-item {
                            padding-bottom: 0;
                        }
                    }

                    .ant-form-item-label {                       

                        label {
                            font-family: var(--seagull-heading-font);
                            font-size: 15px;
                            font-weight: 500;
                            color: $color-grey-dark;

                            .label-help {
                                font-size: 14px;
                                font-family: var(--seagull-body-font), sans-serif;
                                font-weight: 400;
                            }

                            // &.ant-form-item-required:before {
                            //     content: unset;
                            // }
                        }
                    }

                    .ant-input {
                        border-radius: 4px;
                        border-color: $color-grey-light;
                        border-width: 2px;
                    }

                    .ant-upload {
                        &.ant-upload-drag {
                            width: 20%;
                        }
                    }

                    .ant-input-textarea-show-count {
                        &:after {
                            float: left;
                        }
                    }

                    &.editor-wrapper {
                        .ant-form-item-control-input {
                            overflow: hidden;
                            .my-editor {
                                border: 4px solid #e6e6e6;
                                border-radius: 5px;

                                .bf-controlbar {
                                    background-color: #e6e6e6;
                                }
                            }
                        }
                    }
                }

                .delete-button {
                    .anticon {
                        position: relative;
                        top: 6px;
                        margin: 0 8px;
                        color: #999;
                        font-size: 24px;
                        cursor: pointer;
                        transition: all 0.3s;                        
                    }
                }
            }
        }

        &.right-content {

            .ant-card-body {
                padding: 20px;

                .ant-list-items {
                    margin-top: 20px;
                    
                    .ant-list-item-action {
                        >li {
                            font-size: 30px;
                            
                            a {
                                color: $color-accent1;
                            }
                        }
                    }

                    .ant-list-item-meta-description {
                        color: $color-grey-dark;
                    }
                }
            }
        }
    }
}

.create-group-message, .join-group-message {
    .ant-message-notice-content {
        position: absolute;
        right: 30px;
        top: 85px;
        text-align: left;
    }
}