@import '../../assets/variables.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 220px;
    height: 36px;
    line-height: 36px;
  }
  
  .switch input {
    display:none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6a8dac;
    -webkit-transition: .4s;
    transition: .4s; 
    color: #fff;  
    border: 1px solid rgba(#ffffff, .4);

    &:before {
      position: absolute;
      content: attr(data-off);
      height: 34px;
      width: 50%;
      background-color: $color-accent1;
      color: $color-button-text;
      -webkit-transition: .4s;
      transition: .4s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
      text-align: center;
    }

    &::after {   
      position: absolute;
      left: 50%;
      content: attr(data-on);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
      width: 50%;
      text-align: center;
    }
  }  
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked {
    + .slider {
      &:before {
        right: 0;
        content: attr(data-on);        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px;
      }

      &:after {
        left: 0px;
        content: attr(data-off);        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px;
        width: 50%;
      }
    }
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 20px;
  }