@import '../../assets/variables.scss';

.cta-bar {
    .favorite-btn {
        &.favorite-active {
            background-color: $color-accent1;

            &:hover {
                background-color: $color-white;
            }
        }
    }
}