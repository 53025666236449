@import '../../assets/variables.scss';

.profile-page {
    @media(max-width: 767px) {
        padding: 15px;
    }

    input[type="password"] {
        letter-spacing: 7px;
    }
}

.ant-notification {
    z-index: 9999;
}

.ant-card {
    border-radius: 6px;
    margin-bottom: 20px;

    .setting-item {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $color-grey-light;
        padding: 10px 0;
        align-items: center;
        color: $color-grey-dark;

        &:first-child {
            border-top: none;
        }

        .item-left {
            padding-right: 5%;
        }

        .item-right,
        .ant-space-horizontal,
        .ant-checkbox-group,
        .ant-radio-group {


            .anticon {
                color: #121212;
                margin-right: 5px;
            }

            .default-item,
            .ant-radio-wrapper-checked,
            .ant-checkbox-wrapper-checked {
                font-weight: 500;
            }

            .ant-radio-wrapper,
            .ant-checkbox-wrapper {
                flex-grow: 1;
            }
        }

        @media (min-width: 767px) {
            &.items-3 {
                .item-right {
                    flex: 2;

                    :first-child {
                        flex-basis: 23%;
                    }

                    :not(:first-child) {
                        flex-basis: 35%;
                    }
                }
            }
        }
    }
}