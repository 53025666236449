@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.dashboard-content {
  height: 100%;
  overflow: auto;
  position: relative;

  .map-controls {
    padding: 20px 25px;
    margin-left: 150px;

    @include media-breakpoint(md, max) {
      padding: 20px 10px;
      margin-left: 0;
    }

    .ant-tag {
      background-color: $color-accent1;
      color: $color-button-text;
      height: 40px;
      font-size: 13px;
      text-align: center;
      border-radius: 35px;
      line-height: 40px;
      // font-weight: 600;
      border: none;
      z-index: 9;
      padding: 0 10px;

      display: flex;
      align-items: center;

      .anticon {
        vertical-align: middle;
        line-height: 1;
        margin-right: 7px;

        svg {
          g {
            fill: $color-button-text;
          }
        }
      }

      .ant-tag-close-icon {
        font-size: 15px;
        color: $color-button-text;
      }

      .switch-label {
        font-weight: normal;
        font-size: 90%;
        padding: 0 6px 0 8px;
      }

      .ant-switch {
        margin-right: 7px;
        width: 34px;

        &.ant-switch-checked {
          background: var(--color-primary-dark);

          .ant-switch-handle {
            transform: scale(1.6);
          }
        }
      }
    }
  }

  .map-filter-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: var(--color-primary-dark2-80);
    border-radius: 6px;
    border: none;
    color: $color-button-text;
    z-index: 9;

    &.active {
      background-color: $color-accent1;
      color: $color-button-text;

      svg,
      g,
      line,
      circle {
        fill: $color-button-text;
        stroke: $color-button-text;
        fill: #444;
      }
    }
  }

  .favorite-button {
    width: 44px;
    height: 44px;
    background-color: $color-accent1;
    color: $color-button-text;
    border: none;
    position: fixed;
    top: 18%;
    left: 33.6%;
    z-index: 9999;

    @media (max-width: 1400px) {
      top: 23%;
      left: 41.2%;
    }

    @media (max-width: 567px) {
      position: relative;
      width: 24px;
      height: 24px;
      min-width: 24px;
      padding-top: 0;
      left: 70%
    }

    .anticon {
      color: #fff;
      font-size: 30px;
      line-height: 1.65;

      @media (max-width: 567px) {
        font-size: 20px;
        line-height: 1.6;
      }
    }
  }

  .map-filter-drawer {
    position: absolute;

    @include media-breakpoint(md, min) {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          left: 140px;
        }
      }
    }

    @media (max-width: 1400px) {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          width: 43% !important;
        }
      }
    }

    @include media-breakpoint(md, max) {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    .ant-drawer-mask {
      background-color: transparent;
    }

    .ant-drawer-content {
      overflow: hidden;
      background-color: var(--color-primary-dark2-80);
      backdrop-filter: blur(11px);
      color: #fff;

      .ant-drawer-header {
        margin: 0 4%;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border-bottom-color: $color-grey-light;

        .ant-drawer-title {
          color: #fff;
        }

        .ant-drawer-close {
          padding-right: 0;
          color: #fff;
          padding-top: 15px;
        }
      }

      .ant-drawer-body {
        padding-left: 30px;
        font-size: 14px;
        overflow-x: hidden;

        @include media-breakpoint(md, max) {
          padding: 24px 16px;
        }

        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: $color-primary-dark;
          border-radius: 15px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $color-primary-dark;
        }

        .filter-content {
          padding-top: 24px;

          label {
            font-weight: 500;
            color: #fff;
          }

          p {
            font-family: var(--seagull-body-font), sans-serif;
          }

          >div {
            margin-top: 5px;
          }

          .ant-tag,
          .ant-tag-checkable {
            background-color: rgb(219, 225, 230);
            height: 37px;
            font-size: 14px;
            text-align: center;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 20px;
            line-height: 37px;

            &:not(.ant-tag-checkable-checked) {
              color: $color-accent3;

              svg g {
                fill: $color-accent3;
              }
            }

            &.disabled {
              background-color: rgba(219, 225, 230, .8);
              color: rgba(0, 7, 130, .4);
            }

            &.ant-tag-checkable-checked {
              background-color: $color-accent1;
              color: $color-button-text;

              svg g {
                fill: $color-button-text;
              }
            }

            .anticon {
              vertical-align: middle;
            }
          }

          .ant-card {
            margin-top: 5px;
            border-radius: 15px;
            width: 90px;
            height: 90px;
            background-color: rgb(219, 225, 230);
            cursor: pointer;

            &.selected {
              background-color: $color-accent1;
              color: $color-button-text;

              .ant-card-body {
                color: $color-button-text;

                .ant-card-meta-title {
                  color: $color-button-text;
                }
              }
            }

            .ant-card-body {
              padding: 16px 0;
              text-align: center;
              color: $color-accent3;

              .ant-card-meta-title {
                font-size: 13px;
                color: $color-accent3;
              }
            }

            &.square {
              position: relative;
              width: 100%;
              overflow: hidden;
              padding-top: 100%;

              &:before {
                content: '';
                display: block;
              }

              .ant-card-body {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
              }
            }
          }

          .ant-select {
            .ant-select-selector {
              border-radius: 10px;
              background-color: #000;
              border-color: #fff;
            }

            .ant-select-selection-overflow {
              color: var(--color-accent3);
            }

            .ant-select-selection-placeholder,
            .ant-select-arrow {
              color: #fff;
            }
          }
        }
      }

      .ant-drawer-footer {
        margin: 0 4%;
        padding: 10px 0;
      }
    }

    &.favorite-popup {
      .ant-drawer-header {
        border-bottom: none
      }

      .ant-drawer-body {
        text-align: center;
        display: flex;
        align-items: center;

        @media (min-width: 567px) {
          padding-left: 90px;
          padding-right: 90px;
        }

        img {
          width: 86px;
          margin-bottom: 20px;
        }

        p {
          padding: 20px 0;
          font-family: var(--seagull-body-font);
          font-size: 13px;
          font-weight: 500;
        }

        .ant-input,
        .ant-btn {
          border-radius: 20px;
        }

        .ant-btn {
          width: 100%;
          background-color: $color-accent1;
          color: $color-button-text;
          border: none;
          margin-top: 20px;
        }
      }
    }
  }

  @include media-breakpoint(md, min) {
    .no-platform-data-view {
      top: 70%;
      position: relative;
      transform: translate(-50%, 20%);
    }
  }
}

.filter-status-select {
  border-color: gray;
  color: #fff;
  background-color: #000;

  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: transparent;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;

      &:after {
        border-color: #000;
      }
    }
  }

  .custom-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 20px;
    font-size: 14px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $color-grey-dark;
    }

    .text {
      padding-left: 10px;
      flex: 1;
    }
  }
}

.SocialShareRecipientModal {
  .ant-modal-content {
    background: rgba(7 74 116 / 90%);
    border-radius: 20px;
  }

  .ant-modal-body {
    border-radius: 20px;
    padding: 20px;
    background: transparent;
  }

  .socialShareHeading {
    color: white;
    font-family: var(--seagull-heading-font);
    font-size: 20px;
  }

  .viewButton {
    background-color: #f67d28;
    border: #f67d28;
    border-radius: 6px;
    color: white;
    height: 50px;
    padding: 0 40px;
    font-size: 18px;
    font-family: var(--seagull-heading-font);
    margin-top: 15px;
  }

  span.anticon {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 768px) {
  .ant-modal.SocialShareRecipientModal {
    top: 50%;
    translate: 0 -50%;
  }
}

@media only screen and (max-width: 768px) {
  .SocialShareRecipientModal {
    .ant-modal-content {
      width: 100vw;
      translate: -8px 0;
      border-radius: 0;
      background: rgb(7 74 116);

    }

    .viewButton {
      width: 100%;
      margin-top: 0;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      width: 100vw;    
      height: 100%;
      justify-content: start;
      gap: 15px
    }
  }
}