@import '../../../assets/mixins.scss';

.user-groups {
    display: flex;
    flex-direction: column;

    @include media-breakpoint(md, max) {
        padding: 20px 10px;
    }

    .header {
        display: flex;
        color: #fff;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;

        .h2 {
            display: flex;
        }

        a, :not(.ant-btn) span {
            color: #fff;
            font-size: 19px;
        }

        .ant-breadcrumb {
            margin-left: 20px;

            > span:last-child a {
                color: #fff
            }
        }

        .ant-btn {
            border-radius: 4px;
        }
    }

    .ant-card {
        border-radius: 6px;

        .ant-card-body {
            padding: 20px;
        }
        
        .ant-table-thead > tr > th {
            background-color: #fff;
            border-bottom-color: var(--color-grey-light);
        }

        .ant-table-tbody tr td {
            border-bottom-color: var(--color-grey-light);
        }
    }

    .my-groups {
        .ant-table-cell {
            &:last-child {
                text-align: right;
            }
        }
    }
}

.join-group-message {
    .ant-message-notice-content {
        position: absolute;
        right: 30px;
        top: 85px;
        text-align: left;
    }
}