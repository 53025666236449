.parameter {
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 12px;
  padding: 20px 10px;
  height: 100%;
}

.snapshot-parameter {
  border-style: solid;
  border-color: transparent;
  position: absolute;
  width: 100%;
  left: -2px;
  z-index: 1000;
  cursor: pointer;
  top: 0;
  padding-left: 7px;
  height: 100%;
  border-width: 0;
  border-left-width: 2px;

  &:before {
    content: "";
    position: absolute;
    left: -2px;
    top: 15%;
    height: 70%;
  }

  &:hover {
    border-width: 2px;
    border-color: white;
    border-radius: 12px !important;

    .pin-icon {
      visibility: visible;
      border-color: transparent;
      top: -2px;
      right: -2px;

      &.pinned {
        background-color: #fff;
      }

      &:hover {
        border-color: inherit;
      }
    }

    .pinned:hover {
      span {
        color: rgb(246, 125, 40);
      }
    }
  }

  &.mobile-pin {
    padding-left: 0;
    height: auto;
  }

  @media(max-width: 767px) {
    border-left-width: 0;
  }
}

.pin-icon {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  visibility: hidden;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 767px) {
    visibility: visible;
    margin-top: 8px;
  }
}

.notpinned {
  background-color: transparent;
}

.pinned {
  visibility: visible;

  a span.anticon.anticon-ellipsis svg {
    scale: 1;
  }

  span {
    color: white;
    width: 100%;

    @media(max-width: 767px) {
      margin-right: -1px;
    }

    svg {
      scale: 0.8;
    }
  }
}
