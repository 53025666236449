@import "../../assets/mixins.scss";
@import "../../assets/variables.scss";

.announcementModal {
    .ant-modal-content {
        background: $color-primary-dark2-90;
        color: white;
        border-radius: 15px;

        .ant-typography {
            color: white;
        }
    }

    .ant-modal-body {
        background: transparent;
    }

    .ant-modal-close-x .anticon {
        margin-top: 33px !important;
        margin-right: 15px;
        translate: 0 !important;
    }

    .ant-modal-footer button:first-child {
        display: none;
    }
    .ant-modal-footer button:last-child {
        border-radius: 20px;
        width: 100px;
        height: 40px;
    }

    .ant-modal-body a {
        color: $color-accent1;
    }

    .ant-modal-footer {
        background: transparent;
        border-top: none;
    }

    .ant-btn-primary {
        background-color: $color-accent1;
        border-color: $color-accent1;
        &:hover {
            background-color: $color-accent1;
        }
    }
}