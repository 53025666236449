@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.landing-page {
  height: 100%;
  width: 100%;
  position: absolute;
}

.map-window {
  position: absolute;
  right: 0;
  z-index: 999;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 145px;

  @include media-breakpoint(md, max) {
    width: 60%;
  }
}

.landing-drawer {
  top: 78px;

  @include media-breakpoint(md, max) {
    top: 78px;
  }

  @media (max-width: 768px) {
    top: 66px;
  }

  .ant-drawer-close {
    font-size: 24px;
    padding: 10px;

    span {
      color: rgb(230, 230, 230);

      .anticon {
        position: relative;
        top: 30px;
      }
    }
  }

  .ant-drawer-content {
    border-radius: 0;

  }

  @include media-breakpoint(md, min) {
    .ant-drawer-close {
      right: 20px
    }
  }

  &.search-ready {
    width: 100% !important;
  }

  @include media-breakpoint(md, max) {
    width: 100% !important;

    &:not(.search-ready) {
      // height: 35%;
      max-height: 325px;
    }

    .ant-drawer-wrapper-body {
      display: block;
    }
  }

  @include media-breakpoint(xxl, min) {
    &.ant-drawer-open {
      &:not(.search-ready) {
        width: calc(41.66666667% + 28px) !important;
      }
    }
  }

  .ant-drawer-content {
    backdrop-filter: blur(20px);
    background-color: var(--color-primary-dark2-70);

    .ant-drawer-body {
      display: flex;

      @media (max-width: 567px) {
        padding: 16px;
        // top: 40px !important;
      }

      .search-wrapper {
        color: #fff;
        width: 100%;

        .mobile-close-icon-wrapper {
          position: absolute;
          top: 30px;
          right: 8%;

          .anticon {
            width: 10px;
            font-size: 24px;
            color: rgb(230, 230, 230);
          }
        }

        .search-container {
          padding: 0 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @media (max-width: 1200px) {
            padding: 5px;
            margin-top: 40px;
          }

          @include media-breakpoint(lg, min) {
            .search-box {
              margin: 0;  
            }
          }

          &.results-open {

            @include media-breakpoint(md, max) {
              label {
                display: none;
              }

              .search-box {
                margin-top: 0;
              }
            }
          }

          label {
            letter-spacing: 1px;
            padding-bottom: 19px;
            height: 28px;
            font-size: 28px;

            @include media-breakpoint(md, max) {
              font-size: 20px;
            }
          }

          .ant-input-affix-wrapper {
            border-radius: 40px;
            height: 51px;
            font-family: var(--seagull-body-font);
            padding-right: 4px;

            @include media-breakpoint(lg, min) {
              margin-top: 12px;
            }

            @include media-breakpoint(md, max) {
              font-size: 20px;
            }

            .ant-select-selection-placeholder {
              font-weight: 500;
            }

            .ant-btn {
              background-color: $color-accent1;
              color: $color-button-text;
              width: 45px;
              height: 45px;
              border: none;
            }

            .anticon-search {
              margin-right: 10px;
            }
          }

          .ant-row {
            justify-content: center;
            font-family: var(--seagull-heading-font);
            font-size: 15px;
            margin-top: 27px;
        
            @include media-breakpoint(md, min) {
              justify-content: start;
            }
        
            @include media-breakpoint(lg, min) {
              margin-top: 80px; 
            }
            
            a {
              color: rgb(246, 125, 40);
              text-decoration: underline;
            }  
          }
        }

        .search-results {
          padding-top: 65px;
          overflow: visible;

          @include media-breakpoint(lg, min) {
            padding-left: 32px;
          }

          @media (max-width: 576px) {
            padding-top: 10px;
          }

          label {
            font-size: 15px;
          }

          .ant-card {
            border-radius: 10px;

            .ant-card-meta-title {
              color: $color-accent3;
            }
          }

          .chart-card {
            .ant-card-body {
              padding: 24px 0;
              position: relative;

              .ant-card-meta-title {
                padding: 5px 24px;
              }
            }
          }

          .map-card {
            height: 140px;

            .ant-card-body {
              padding-top: 18px;
            }

            .icon-text {
              font-size: 12px;
              font-weight: 500;
            }

            .ant-card-meta-title {
              margin-top: 8px;
              white-space: pre-line;
              line-height: 19px;
              height: 50px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.landing-page-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1000;

  .landing-page-button {
    z-Index: 1000;
    position: absolute;
    bottom: 70px;
    width: 95%;
    background-color: $color-accent1;
    font-family: Work Sans;
    border-color: $color-accent1;
    height: 53px;
    color: white;
    box-shadow: 2px 2px 12px 2px rgba(0,7,130,0.12);
  }
}
