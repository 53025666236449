@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.dataset-card-wrapper {
    background-color: var(--color-primary-dark2);
    border-radius: 10px;
    color: $color-white;
    padding: 20px;


    .title {
        font-family: 'PulpDisplay-SemiBold', serif;
        font-size: 18px;
    }

    .sub {
        font-size: 14px;

        &.org {
            margin-top: 10px;
        }
    }
    
    .cta-wrapper {
        border-top: $color-grey-dark 1px solid;
        margin: 10px 0;

        display: flex;
        justify-content: space-between;
    }
}
