@import '../../assets/variables.scss';

.ant-modal-wrap {
    pointer-events: auto !important;
}

.confirm-modal {
    text-align: center !important;

    .ant-modal-close {
        // top: 8px;
        // right: 10px;
        color: $color-grey-default; 
        
        .anticon {
            font-size: 20px;
        }
    }

    .ant-modal-body {
        padding: 40px 24px;

        .title {
            margin-bottom: 10px;
        }
    
        .ant-btn {
            border-radius: 20px;
        }
    
        a {
            margin-right: 20px;
        }

        .removed {
            text-align: center;
            vertical-align: middle;
            font-weight: 500;
            padding: 24px;
            color: $color-grey-dark;

            .anticon {
                color: rgb(124, 223, 9);
                font-size: 32px;
                margin-bottom: 10px;
            }
        }
    }
    
}