.ant-message {
    z-index: 10003;
}

#profile_phone_number {
    border: none;
}

.ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.ant-form-item-control-input {
    min-height: auto;
}

.PhoneInputInput {
    padding: 7px;
    border: 1px solid #999;
    border-radius: 3px;
    outline: none;
}