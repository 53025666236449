@import './assets/variables.scss';

body {
  margin: 0;
  font-family: var(--seagull-body-font), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  overflow: hidden;
}

.App {
  height: 100%;

  .ant-layout {
    height: 100%;

    .ant-layout-content {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #ccc;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 15px;
      }
    }
  }

  .app-content {
    width: 100%;
    height: 100%;
  }
}

.bg-transparent {
  background-color: transparent;
  z-index: 1001;
  width: calc(100% - 80px);

  +.ant-layout-content {
    margin-top: -58px;
  }
}

// Buttons 

.ant-btn {
  &:hover {
    color: $color-accent1;
  }

  &:focus {
    color: white;
    background-color: --color-primary-dark4;
  }

  &:hover:focus {
    color: $color-accent1;
    background-color: white;
  }
}

.ant-btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: white;
  }
}

/* Helpers */
.select-dark {
  &.ant-select {
    .ant-select-selector {
      background-color: #000;
      color: #fff;
    }

    .ant-select-arrow {
      color: $color-grey-dark;
      font-size: 14px;
    }
  }

  &.ant-select-item-option {
    background-color: #000;
    color: #fff;
  }
}

.select-dark-dropdown {
  background-color: #000;
  color: #fff;

  .ant-select-item {
    background-color: #000;
    color: #fff;

    &:not(.ant-select-item-group) {

      &:hover,
      &:focus {
        background-color: $color-accent1;
        color: $color-button-text;
      }
    }
  }

  .ant-select-item-option-selected {
    background-color: $color-accent1;
    color: $color-button-text;
  }
}

.ant-table-tbody {
  tr {
    td {
      border-bottom-color: $color-grey-default ;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h1 {
  font-size: 28px;
  font-family: var(--seagull-heading-font);
  font-weight: 500;

  &-w {
    @extend .h1;
    color: #fff;
  }
}

.h2 {
  font-size: 19px;
  font-family: var(--seagull-heading-font);
  font-weight: 500;

  &-w {
    @extend .h2;
    color: #fff;
  }
}

.h3 {
  font-size: 15px;
  font-family: var(--seagull-heading-font);
  font-weight: 500;

  &-w {
    @extend .h3;
    color: #fff;
  }
}

.text-white {
  color: #fff;
}

.caption {
  font-family: var(--seagull-body-font);
  font-size: 12px;
  color: $color-grey-default;

  &-w {
    @extend .caption;
    color: #fff;
  }
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.bg-warning {
  background-color: $color-accent1;
  color: $color-button-text;
}

.bg-dark {
  background-color: #031f31;
  color: #fff;
}

.bg-darker {
  background-color: #061623;
  color: #fff;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.socialShare .ant-modal-content,
.downloadChartModal .ant-modal-content {
  border-radius: 20px;
}

.socialShare .ant-modal-body,
.downloadChartModal .ant-modal-body {
  border-radius: 20px;
}

.communitySignup .ant-modal-body {
  padding: 35px;
}

.downloadChartModal .ant-modal-content {
  background-color: rgba(7, 74, 116, 0.9) !important;
}

.downloadChartModal .ant-modal-body {
  background-color: transparent !important;
}

.parameter-detail .options-button:hover,
.parameter-detail-main .favorite-btn:hover,
.parameter-detail-main .dotsbutton:hover {
  background-color: var(--color-accent1) !important;
  border-color: var(--color-accent1) !important;
  color: white !important;
  opacity: 1 !important;
}

.parameter-detail .favorite-btn {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.parameter-detail .favorite-btn:focus {
  color: white !important;
}

.download-data .ant-modal-body,
.download-data .ant-modal-content {
  border-radius: 20px;
}

.download-data .ant-modal-body {
  padding: 20px 24px;
}

@media only screen and (min-width: 768px) {
.download-data .ant-modal-content,
.download-data .ant-modal-body,
.socialShare .ant-modal-content,
.socialShare .ant-modal-body {
  background-color: rgba(7, 74, 116, 0.7);
}
}

@media only screen and (min-width: 768px) {
  .ant-modal.download-data, .ant-modal.downloadChartModal {
    top: 50%;
    translate: 0 -50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  .ant-modal.download-data {
    width: fit-content !important;
  }
}

@media only screen and (max-width: 768px) {
  .socialShare .ant-modal-body, .downloadChartModal .ant-modal-body {
    height: 90vh;
    border-radius: 0;
  }

  .socialShare .ant-modal-content {
    width: 100vw;
    translate: -8px 0;
    background-color: transparent !important;
    height: 100vh;
  }

  .socialShare.ant-modal {
    margin: 0 auto;
  }

  .download-data .ant-modal-content {
    width: 100vw;
    translate: -8px 0;
  }
}

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.65);
  z-index: 9999 !important;
}

@media only screen and (max-width: 768px) {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .socialShare, .downloadChartModal, .download-data {
    .ant-modal-content {
      width: 100vw;
      translate: -8px 0;
      border-radius: 0;
      background-color: transparent;
    }

    .viewButton {
      width: 100%;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 90%;
      justify-content: flex-start;
      border-radius: 0;
    }
  }

  .socialShare .ant-modal-body {
    height: fit-content !important
  }

  .download-data .ant-modal-body {
    height: 100% !important;
    padding-bottom: 50 !important;
  }

  .download-data.ant-modal {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100%;
      justify-content: space-around;
      border-radius: 0;
      padding-bottom: 0;
  }

  .ant-modal.download-data {
    margin: 0 auto;
  }
}