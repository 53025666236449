@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.search-drawer {

    .ant-drawer-mask {
        background-color: transparent;
    }

    .ant-drawer-header {
        background-color: transparent;
        border: none;
        color: #fff;

        .logo {
            left: 0
        }

        .ant-drawer-close { 
            font-size: 24px;
            padding: 10px;
        }
    }

    .ant-drawer-content {
        font-size: 14px;
        background-color: var(--color-primary-dark2-80);
        backdrop-filter: blur(10px);
        color: #fff;

        .ant-input-affix-wrapper {
            border-radius: 40px;
            height: 51px;
            font-family: 'var(--seagull-body-font)';
            padding-right: 4px;
            font-size: 15px;

            .ant-select-selection-placeholder {
                font-weight: 500;
            }

            .ant-btn {
                background-color: $color-accent1;
                color: $color-button-text;
                color: #fff;
                width: 45px;
                height: 45px;
                border: none;
            }
        }
    }
}