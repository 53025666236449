@import '../../../../assets/variables.scss';

.ant-radio-group {
  .ant-radio-button-wrapper {
    border-radius: 30px;
    border-style: none;
    margin-right: 5px;
    font-family: Work Sans;
    font-size: 14px;
    color: #a3a3a3;
    text-decoration: none solid rgb(163, 163, 163);
    &::before {
      background-color: #ffffff00;
    }
    &:hover {
      &::before {
        background-color: #ffffff00;
      }
      background-color: $color-grey-light;
      box-shadow: unset;
    }
    &:focus-within {
      box-shadow: unset;
    }
  }
  .ant-radio-button-wrapper-checked {
    border-radius: 30px;
    border-style: none;
    background-color: $color-grey-light;
    color: #444444;
  }
}

.setting-item {
  .item-left {
    .ant-radio-group {
     display: inline;
   }
  }
}

.mobile-container {
  .ant-radio-group,
  .ant-checkbox-group {
    width: 100%;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    border-top: 1px solid $color-grey-light;
    padding: 20px 0;
  }
}

.setting-item {
  .item-description {
    max-width: 350px;
  }
}

.param-name {
  font-family: Work Sans;
  font-size: 14px;
  color: #444444;
  text-decoration: none solid rgb(68, 68, 68);
  line-height: 40px;
  display: inline;
  padding-right: 15px;
}

.pinned-check {
  .ant-checkbox {
    display: none;
  }
}

