.add-ice-thickness {
  .ant-select .ant-select-selection-placeholder, .ant-select .ant-select-arrow {
    color: #aaa;
  }

  .ant-select-selection-item {
    color: #222;
  }

  .add-ice-thickness {
    .form-field {
      margin-bottom: 10px;
    }
  }

  .mapboxgl-map {
    width: 100% !important;
    height: 100% !important;
  }

  .map-container > div {
    width: 100% !important;
    height: 100% !important;
  }

  .unit-options {
    min-width: 70px;
  }

  .number-input {
    max-width: 170px;
    text-align: right;
  }

  .map-container {
    margin: 10px 0 20px;
  }

  .submit-button:focus {
    color: white !important;
  }

  .ant-modal-body {
    background-color: #fff;

    .ant-btn-primary {
      background-color: var(--color-accent1);
      border: 0;
      font-weight: 600;
    }
  }

  .time-picker {
    min-width: 136px;
  }

  .ant-picker {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    
    input {
      color: rgba(0, 0, 0, 0.85);
    }
    input::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .current-location-button {
    margin-top: 5px;
  }

  @media (max-width: 576px) {
    .ant-form-item {
      margin-bottom: 10px;
    }

    .current-location-button {
      margin-bottom: 10px;
    }
  }
}

.ice-thickness-date-picker-dropdown {
  .ant-picker-panel-container {
    background: transparent;
  }

  .ant-picker-panel-container .ant-picker-panel {
    background: transparent;
  }

  .ant-picker-panel-container .ant-picker-panel .ant-picker-content .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-picker-panel-container .ant-picker-panel .ant-picker-content .ant-picker-cell-disabled:before {
    background: #f5f5f5;
  }

  .ant-picker-panel-container {
    background: #fff;
  }
}

.ant-picker-time-panel {
  background: #fff;
}

.ant-picker-footer {
  background: #fff;
}

.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  color: white;
}