.wave-graphic {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 240px;
  background-repeat-x: repeat;
  background-position: bottom;
  background-repeat-y: no-repeat;

  svg {
    width: 100%;
  }

  // to cover slight gap between repeats
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 171px;
    background-color: #4db1f7;
  }
}

.page-content {
  text-align: center;
  padding-top: 220px;
  padding-bottom: 200px;
  color: #fff;

  .container {
    position: relative;
    max-width: 400px;
    margin: 0 auto;

    .sinking-buoy {
      position: absolute;
      left: -80px;
      top: 90%;

      @media(max-width: 767px) {
        left: 0px;
        top: 110%;
      }
    }
  }

  h1 {
    color: #fff;
    font-size: 62px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
  }

  .feedback span {
    text-decoration: underline;
  }
}