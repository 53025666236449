@import '../../assets/mixins.scss';

.custom-drawer {
    position: absolute;

    @include media-breakpoint(sm, min) {
      .drawer-content {
        padding-top: 45px;
      }
    }

    @include media-breakpoint(xl, max) {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          width: 45% !important;
        }
      }
    }

    @include media-breakpoint(md, max) {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    .ant-drawer-mask {
      background-color: transparent;
    }

    .ant-drawer-content {
      overflow: hidden;
      color: #fff;
      background-color: transparent;

       //Apply padding top for small screens
       @include media-breakpoint(md, max) {
        //padding-top: 40px;
      }

      .ant-drawer-header {
        margin: 0 4%;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;

        .ant-drawer-title {
          color: #fff;
        }

        &, &-no-title {
          button {            
            color: #fff;
            font-size: 19px;
          }
        }
      }

      .ant-drawer-wrapper-body {
        font-size: 14px;
        height: 100%;
        backdrop-filter: blur(90px);
        position: relative;
        background: rgba(6, 25, 40, 0.9);
        box-shadow: 0px 1px 2px rgba(0,0,0,0.2);

        ::-webkit-scrollbar {
            width: 5px;
        }
    
        ::-webkit-scrollbar-track {
            background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
            background: var(--color-primary-dark3);
            border-radius: 15px;
        }

        //Apply border radius for small screens
        @include media-breakpoint(md, max) {
          border-top-left-radius: 40px;
          border-top-right-radius: 40px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 150px;
          background: rgb(15,57,89);
          background: linear-gradient(180deg, rgba(15,57,89,0) 0%, rgba(15,57,89,1) 30%, rgba(15,57,89,1) 100%);
        }
      }
    }

    .ant-drawer-footer {
      position: relative;
      z-index: 101;
    }

    &.ant-drawer-bottom {
      .ant-drawer-content-wrapper {
        box-shadow: none;
      }

      .ant-drawer-wrapper-body {
        &:after {
          display: none;
        }
      }
      
      .ant-drawer-body {
        overflow-y: hidden;
      }

      .ant-drawer-footer {
        display: none;
      }
    }
}
