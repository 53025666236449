@import '../../../assets/variables.scss';

.platforms-page {

    @media (max-width: 567px) {
        padding: 20px 10px;
    }

    h2 {
        display: flex;
        align-items: center;
    }

    .h3 {
        color: $color-grey-dark;
    }

    .ant-card {
        border-radius: 6px;

        table {
            .ant-table-thead {
                > tr {
                    > th {
                        background-color: #fff;
                        color: $color-grey-dark;
                        font-size: 12px;
                    }
                }
            }

            .ant-table-tbody {                
                td {
                    border-bottom-color: $color-grey-light;
                    padding-bottom: 60px;
                }
            }
        }

        //list is for mobile
        .ant-list-items {
            .ant-list-item:first-child {
                border-top: 1px solid $color-grey-light;
                margin-top: 20px;
            }

            .ant-list-item {
                border-color: $color-grey-light;
                padding: 21px 0;
            }
        }

        .ant-btn {
            color: $color-accent1;
            border-color: $color-grey-light;
            border-width: 2px;
            border-radius: 4px;
            font-weight: 500;
        }

        .add-platform {
            padding-top: 20px;

            a {
                color: $color-accent1;
                font-weight: 500;
            }
        }
    }
}