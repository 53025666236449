@import '../../../../assets/variables.scss';

.mobile-container {
    
    .header {
        display: flex;
        margin-bottom: 7px;

        .anticon {
            margin-top: 6px;
            margin-right: 10px;
        }
    }

    .ant-card {        

        .line-items {
            .line-item {
                padding: 20px 0;

                &:not(:first-child) {
                    border-top: 1px solid $color-grey-light;
                }

                >div {
                    margin-top: 10px;
                }
            }
        }
    }

    .action-btns {
        button {
            border: none;
        }
    }
}