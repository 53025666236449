@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

.ant-picker-date-range-wrapper {

  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-super-prev-btn {
    visibility: visible !important;
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view) {
    color: rgba(255, 255, 255, 0.45);
  }

  .ant-picker-panel-container .ant-picker-panel {
    border: 0;
  }
}

.parameter-detail-main {
  .ant-picker-range {
    .ant-picker-input {
      max-width: 76px;
    }
  }

  .parameter-name {
    padding: 5px 0px;
  }

  .parameter-description {
    color: $color-grey-light;
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.options-button {
  border-color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0, .6);
}

.ant-collapse {
  &.ant-collapse-icon-position-right {
    background-color: transparent;
  }

  .ant-collapse-item {
    .ant-collapse-header {
      color: #ffffff;

      .ant-collapse-arrow {
        padding: 0px;
      }
    }

    .ant-collapse-content.ant-collapse-content-active,
    .ant-collapse-content.ant-collapse-content-inactive {
      background-color: transparent;
    }
  }


}

.chart-container {
  display: flex;
  flex-direction: row;

  .chart {
    flex: 3;
    height: 80vh;
    min-height: 300px;

    @include media-breakpoint(md, min) {
      padding: auto;
    }
  }
}

.dataset-detail {
  background: #00000054;
  min-width: 280px;
  max-width: 280px;
  font-family: 'Work Sans';
  padding: 18px 10px;

  h3,
  h4 {
    color: #ffffff;
  }

  h3 {
    font-family: 'PulpDisplay-SemiBold';
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
  }

  ul {
    list-style-type: none;
    font-size: 14px;
    padding-inline-start: 0px;
    padding-top: 10px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .legend-indicator {
      width: 15px;
      height: 15px;
      border-radius: 12px;
      margin-right: 10px;
    }
  }

  .parameter-type-selector {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    div {
      flex: 1;
      color: rgba(255, 255, 255, 0.8);

      div {
        display: inline;
        cursor: pointer;
      }

      &.active {
        font-weight: bold;
        color: rgba(255, 255, 255, 1);

        div {
          border-bottom: 2px solid #f67d28;
        }
      }
    }

  }


}

@media screen and (max-width: 767px) {
  .chart-container {
    display: flex;
    flex-direction: column;
  }

  .dataset-detail {
    background: #00000054;
    max-width: 100%;
    font-family: 'Work Sans';
    padding: 18px 10px;
  }

  .custom-tooltip-text {
    opacity: 0;
  }
}


.period-select {
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;

  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.35);
    border: none;
    border-radius: 6px;
    height: 40px;

    .ant-select-selection-search-input,
    .ant-select-selection-item {
      line-height: 40px;
    }
  }
}

#poreSizes .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: 1px #f67d28 solid !important;
  border-radius: 100px;
  width: 130px;
}

.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  border-radius: 15px !important;
}

.ant-dropdown-menu-item .ant-input-number {
  width: 80px;
}

.ant-dropdown-menu-item:hover {
  background-color: transparent;
}

.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-top {
  z-index: 9999 !important;

  .ant-tooltip-inner {
    color: #000000d9;
  }
}

.ant-input-number-input {
  height: 40px;
  translate: 0 5px;
}

.ant-input-number-input::after {
  content: 'm';
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-btn:hover {
  border-color: rgb(217, 217, 217);
}

.viewButton.ant-btn:hover {
  background-color: white;
}

.ant-btn:focus {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-top {
  display: none;
}

.ant-slider-mark-text {
  color: white;
}

.downloadChartSubMenu .ant-dropdown-menu-submenu-title {
  background-color: transparent;
}

ul.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  border-radius: 15px;
}

.date-range-filter span.anticon {
  color: white;
}