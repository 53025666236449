@import '../../assets/mixins.scss';

.no-platform-data-view {

    @include media-breakpoint(md, min) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .d-flex {
        align-items: center;
    }

    .right-content {
        color: #fff;

        @include media-breakpoint(md, min) {
            margin-left: 40px; 
        }

        @include media-breakpoint(md, max) {
            margin-top: 20px;
        }
    }
}