.omics-dataset-summary {
  .sample-date {
    margin-top: 10px;
  }

  .tooltip-header {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .apexcharts-pie-series {
    path {
      stroke-width: 0 !important;
    }
  }

  .apexcharts-datalabels {
    display: none !important;
  }

  .apx-legend-position-right {
    top: 0 !important;
  }
  
  .apexcharts-legend.apx-legend-position-right {
    justify-content: center;
  }

  .apexcharts-legend {
    max-width: 250px;
  }

  .apexcharts-legend-series {
    .apexcharts-legend-text {
      font-size: 13px !important;
      color: #fff !important;
    }
  }

  .apexcharts-legend-marker {
    border-radius: 0 !important;
    margin-right: 7px;
  }

  .apexcharts-legend-text {
    font-family: var(--seagull-body-font) !important;
  }

  div.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    width: 400px;
  }

  .apexcharts-canvas {
    overflow: visible;
    height: auto !important;

    svg {
      height: 100%;
      width: 400px;
    }

    foreignObject {
      height: 100%;
    }
  }

  .apexcharts-canvas {
    display: flex;
  }

  g.apexcharts-inner.apexcharts-graphical {
    transform: translate(10px, 10px);
  }
}
