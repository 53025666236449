@import '../../assets/variables.scss';

.apexcharts-tooltip {
    z-index: 9999;

    .arrow_box {
        background-color: rgba(#000000, .5);
        text-align: center;
        padding: 10px;
    }
}

.windroseWrapper {
    display: flex;
    div {
        margin-left:auto;
        margin-right:auto;
    }
    div svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        g g g text {
            fill: white;
        }
    }


    .wind-leg-container{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        position: relative;
        min-width: 120px;
        border-radius: 2px;
        display: flex;
        margin-left: unset;
        align-items: flex-start;
        margin-top: 40px;
        margin-bottom: 40px;

        .wind-mag-leg{
            display: flex;
            position: absolute;
            height: 100%;
            min-width: 22px;
            border: $color-grey-light 1px solid;
            border-radius: 15px;
            background: linear-gradient(
                            #F67D28,
                            #EECC11,
                            #B9FF7D,
                            #42f456,
                            #42f4ce,
                            #42c5f4,
                            #4242f4,
                            #8e44ad
            );
            flex-wrap:wrap;
            align-content: space-between;
        }
        .wind-leg-item{
            height: 20px;
            width: 100%;
            border: $color-grey-light 1px solid;
            border-radius: 50%;
            span{
                margin-left:25px;
            }
        }
        .wind-leg-item .wind-leg-item-text {
            visibility: visible;
            width: 65px;
            height: 25px;
            left: 130%;

            color: #ffffff;
            text-align: right;
            border-radius: 1px;
            position: absolute;
            z-index: 1;

        }
    }
    .mobile-wind-leg-container{
        width: 100%;
        height: 22px;

        margin-top:20px;

        .wind-mag-leg{
            margin-right:0px;
            margin-left:0px;
            display: flex;
            position: absolute;
            height: 24px;
            width:100%;
            border: $color-grey-light 1px solid;
            border-radius: 15px;
            background: linear-gradient(
            90deg,
                            #8e44ad,
                            #4242f4,
                            #42c5f4,
                            #42f4ce,
                            #42f456,
                            #B9FF7D, // 5-6
                            #EECC11, // 6-7
                            #F67D28 // 7+
            );
            flex-wrap:wrap;
            justify-content: space-between;
        }
        .wind-leg-item{
            height: 20px;
            width: 20px;
            border: $color-grey-light 1px solid;
            border-radius: 50%;
            margin:0;
            span{
                margin-top:25px;
            }
            padding-top:20px;
            text-align: right;
        }
        .wind-leg-item .wind-leg-item-text {
            visibility: visible;
            width: 65px;
            height: 25px;
            left: 130%;

            color: #ffffff;
            text-align: right;
            border-radius: 1px;
            z-index: 1;

        }
    }
}

#chart {
    .ant-slider-step, .ant-slider-mark {
        display: none;
    }

    .ant-slider-handle {
        background-color: var(--color-primary) !important;
        border: solid 2px #1890ff !important;
    }

}

.parameter-detail g.apexcharts-xaxis text:first-child {
    display: none;
}
