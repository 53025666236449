.platform-filters {
    .ant-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 4px 2px rgba(0,0,0,0.1);
    }

    .nest-tag,
    .seagull-tag {
        padding-left: 15px;
        padding-right: 15px;
        .anticon {
            margin-right: 5px;
        }
    }

    .nest-tag {
        svg {
            margin-top: -1px;
        }
    }

    .seagull-tag {
        svg {
            width: 62px;
            margin-bottom: -2px;
        }
    }
}