@import '../../assets/variables.scss';

.sidebar {
    background-color: $color-primary;

    .logo {
        height: 76px;
        line-height: 56px;
    }

    .betaSign {
        font-family: Work Sans;
        font-style: italic;
        background-color: $color-black;
        border: 3px solid $color-black;
        border-radius: 10px;
        font-size: 14px;
        padding: 0px 7px;
        font-weight: 400;
        margin: 5px 0px 0px 0px;
        width: fit-content;
    }

    .ant-menu {
        background-color: $color-primary;
        border-right: none;
        height: 100%;

        .ant-menu-item {
            margin-bottom: 80px;
            padding-left: 18px;
            white-space: normal;
            height: auto;
            line-height: normal;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-left: 20px;
            width: calc(100% - 20px);
            border-radius: 10px 0 0 10px;
            background: $color-primary;
            position: relative;
            overflow: visible;

            a {
                color: #fff;
            }

            &::after,
            &::before {
                opacity: 0;
                border-right: none;
                transform: none;
            }
        }

        .ant-menu-item-selected {
            color: #fff;
            background-color: $color-primary-dark;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            transition: none;
            font-weight: 500;

            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                right: 0px;
                transition: opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &::after {
                top: -20px;
                background: radial-gradient(circle at top left, $color-primary 20px, $color-primary-dark 21px);
                opacity: 1;
            }

            &::before {
                bottom: -20px;
                background: radial-gradient(circle at bottom left, $color-primary 20px, $color-primary-dark 21px);
                opacity: 1;
            }
        }
    }
}