@mixin scrollbar($track-color, $thumb-color) {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 15px;
    }
}

// A map of breakpoints.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin media-breakpoint($breakpoint, $direction) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    
    @if $direction == max {
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }

    @if $direction == min {
        @media (min-width: ($breakpoint-value)) {
            @content;
        }
    }
} 