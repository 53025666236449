.unauth-wrapper {
    border-radius: 50px;
    color: white;
    margin: 25px 15px;
    padding: 50px;

    span {
        display: block;
        text-align: center;

        &:first-of-type {
            font-size: 24px;
            font-weight: bold;
        }
    }
}