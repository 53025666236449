@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';

.dataset-detail-page {
    padding: 0 30px;
    height: 100%;
    position: relative;
    
    @include media-breakpoint(md, max) {
        padding: 0 10px;
    }

    .ant-breadcrumb {
        a, .ant-breadcrumb-separator {
            color: $color-grey-light;
        }

        >span {
            &:last-child {
                a {
                    color: $color-grey-light !important;
                }
            }
        }
    }

    @include media-breakpoint(md, max) {
        .header {
            display: flex;

            .ant-btn {
                width: 100%
            }
        }
    }

    .header {
        // display: flex;
        // justify-content: space-between;

        .cta-bar {
            .favorite-btn {
                border-radius: 4px;
                border: none;
            }
        }
    }

    .main {
        color: $color-white;
        margin-top: 25px;

        .tab-header {
            color: $color-white;
            font-size: 24px;
            font-weight: bolder;
            position: inherit;

            @include media-breakpoint(md, max) {
                font-size: 18px;
            }
        }

        .tab-body {
            margin-top: 10px;

            .summary {
                font-family: 'PulpDisplay-SemiBold', serif;
                font-size: 16px;
            }

            .dataset-links {
                margin-top: 25px;

                .links {
                    .link {
                        background-color: $color-white;
                        border-radius: 20px;
                        color: $color-grey-dark;

                        div {
                            align-self: center;
                        }

                        .link-btn {
                            background-color: $color-accent1;
                            color: $color-white;
                            margin-left: 10px;
                            border-radius: 20px;
                        }
                    }
                }
            }

            .ant-table-wrapper {
                                    
                .ant-table {
                    background-color: var(--color-primary-dark2);
                    color: #fff;
                    font-size: 16px;

                    .ant-table-thead {
                        tr {
                            th {
                                background-color: var(--color-primary-dark2);
                                color: #fff;
                                border-bottom: none;
                            }
        
                            &.ant-table-row-hover:not(.ant-table-expanded-row),
                            &:hover:not(.ant-table-expanded-row) {
                                td {
                                    background: unset; //Change the existing color to `unset`
                                }
                            }
                        }
                    }
        
                    .ant-table-tbody{
                        tr {
                            &.ant-table-row-hover:not(.ant-table-expanded-row),
                            &:hover:not(.ant-table-expanded-row) {
                                td {
                                    background: unset; //Change the existing color to `unset`
                                }
                            }
                            td {
                                &.ant-table-column-sort {
                                    background-color: var(--color-primary-dark2);
                                }

                                a {
                                    color: $color-white;
                                }
                            }
        
                        }
                    }
        
                    .ant-table-column-sorter {
                        margin-left: 0px;
                    }
                }


            }
        }

        @include media-breakpoint(lg, min) {
            height: 90%;
        }

        .tabs-container {
            height: 100%;
            margin: 15px 0;

            .ant-tabs-card {
                height: 100%;

                .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                        .ant-tabs-nav-list {
                            .ant-tabs-tab {
                                background-color: transparent;
                                border: none;
                                color: $color-white;
                                width: 150px;
                                justify-content: center;

                                &-active {
                                    background-color: var(--color-primary-dark2);
                                    border-top-left-radius: 20px;
                                    border-top-right-radius: 20px;

                                    .ant-tabs-tab-btn {
                                        color: $color-white;
                                    }
                                }
                            }

                            .ant-tabs-tab:not(:first-child) {
                                &.ant-tabs-tab-active + .ant-tabs-content {
                                    border-top-left-radius: 0;
                                }                                
                            }
                        }
                    }
                }

                .ant-tabs-content {
                    margin-top: -16px;        
                    height: 100%;

                    >.ant-tabs-tabpane {
                        padding: 28px;
                        color: $color-white;
                        border-radius: 20px ;                        
                        overflow-y: scroll;
                        overflow-x: hidden;
                        background-color: var(--color-primary-dark2);
                        
                        @include scrollbar(transparent, var(--color-primary-dark3));    
                        
                        &:first-child {
                            border-top-left-radius: 0;
                        }

                        p {
                            margin-bottom: 58px;
                        }
                    }
                }

                >.ant-tabs-nav::before {
                    display: none;
                }
            }

            & [data-theme='compact'] {
                > .ant-tabs-card .ant-tabs-tab {
                    background: transparent;
                    border-color: transparent;
                }
            }
        }

        .slider-toggle {
            height: 36px;
            border-radius: 40px;
            background-color: #6a8dac;
            border: 1px solid #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            font-size: 12px;

            .toggle-item {
                width: 100%;
                text-align: center;
                height: 34px;
                line-height: 36px;
                
                &.active {
                    background-color: $color-accent1;
                    border-radius: 40px;
                }
            }
        }
    }
}