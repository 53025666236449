@import '../../../assets/mixins.scss';
@import '../../../assets/variables.scss';
.ant-picker-panel-container {
  background-color: #000;
}
.ant-picker-range .ant-picker-input {
  max-width: 76px;
}
.custom-date-filter-footer {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.seagull-range-picker {
  .period-menu {
    background: none;
    border: none;
    color: #fff;
    line-height: 30px;

    .ant-menu-item-active,
    .ant-menu-item-selected {
      color: #fff;
      border-color: $color-accent1;
    }

    & .ant-menu-item-disabled {
      color: rgba(255, 255, 255, 0.6) !important;
    }
  }

  .custom-date-filter {
    background-color: $color-primary;
    border-radius: 20px;
    border: none;
    color: #fff;

    @include media-breakpoint(md, max) {
      margin-top: 10px;
      width: 100%;
    }

    input {
      color: #fff;
    }

    .ant-picker-range-separator {
      padding-left: 0;
    }
  }

  .ant-btn {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  &-main, &-others {
    @include scrollbar(transparent, var(--color-primary-dark3));
  }

  &-main {
    .parameter-card {
      overflow: hidden;

      .depths-toolbar {
        background-color: #000000;
        font-size: 12px;
        color: #fff;
        padding: 10px;
        position: relative;
        @include scrollbar(transparent, var(--color-primary-dark3));

        div {
          cursor: pointer;

          div {
            svg {
              g {
                color: #fff
              }
            }
          }
        }

        > div:not(:first-child) {
          margin-bottom: 30px;
        }

        > div:first-child {
          margin-bottom: 24px;
        }
      }
    }
  }

  &-others {
    background-color: #031f31;
    padding: 20px;

    @include media-breakpoint(md, max) {
      background: none;
      padding: 0;
    }
  }

  .featured-parameter {
    border: none;
    border-radius: 15px;
    padding-bottom: 0;
    background-color: inherit;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;

    @include media-breakpoint(md, max) {
      background-color: #052a4c;

      .ant-card-body {
        padding: 10px;
      }
    }
  }
}
