@import '../../../assets/variables.scss';

.favorites-page {

    @media (max-width: 567px) {
        padding: 20px 10px;
    }

    .h3 {
        color: $color-grey-dark;
    }

    .ant-card {
        border-radius: 6px;
        margin-bottom: 40px;
        
        .caption {
            padding-bottom: 20px;
        }

        .title {
            display: flex;
            align-items: center;
        }

        .ant-btn {
            color: $color-accent1;
            border-color: $color-grey-light;
            border-width: 2px;
            border-radius: 4px;
            font-weight: 500;
        }

        a {
            &.ant-btn-icon-only {
                padding: 0;
            }
        }

        .ant-tag {
            background-color: $color-accent1;
            color: $color-button-text;
            height: 37px;
            font-size: 13px;
            text-align: center;
            border-radius: 35px;
            line-height: 37px;
            font-weight: 600;
            border: none;
            z-index: 0;
            margin-bottom: 10px;
    
            .anticon {
            vertical-align: middle;
            }
    
            .ant-tag-close-icon {
            font-size: 15px;
            color: #fff;
            }
        }

        .more-text {
            color: $color-grey-dark;
            font-size: 12px;                    
        }

        .parameters {
            color: $color-grey-dark;

            .anticon {
                color: #121212
            }
        }

        .nickname {
            a {
                color: $color-accent1;
                text-decoration: underline;
            }

            .anticon {
                color: $color-accent1;
            }
        }

        table {
            .ant-table-thead {
                > tr {
                    > th {
                        background-color: #fff;
                        color: $color-grey-dark;
                        font-size: 12px;
                    }
                }
            }

            .ant-table-tbody {   
                
                td {
                    border-bottom-color: $color-grey-light;
                    padding-bottom: 60px;

                    .location-title {
                        text-transform: capitalize;
                    }
                }
            }
        }

        //list is for mobile
        .ant-list-items {
            .ant-list-item:first-child {
                border-top: 1px solid $color-grey-light;
            }

            .ant-list-item {
                border-color: $color-grey-light;
                padding: 21px 0;
            }
        }
    }

    .ant-card-body {
        .line-item {
            .capitalize {
                text-transform: capitalize;
            }
        }
    }
}