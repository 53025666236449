@import '../../../../../assets/variables.scss';

.create-alert-drawer {
    z-index: 1040;

    .ant-form {
        color: #fff;
    }
    
    .ant-drawer-content {
        font-size: 15px;
        color: #fff;
        padding-top: 0;
        overflow-x: hidden;

        .ant-drawer-wrapper-body {
            &:after {
                height: 0;
            }

            .ant-drawer-body {
                overflow-x: hidden;
            }
        }

        .drawer-content {
            padding-top: 0;
        }

        .header {
            margin-bottom: 40px;
            font-size: 19px;
        }

        .ant-form {
            label {
                color: #fff;
            }
        }

        .ant-select {
            margin-bottom: 20px;

            .ant-select-selector {
                border: none;
                border-radius: 6px;
            }
        }

        .switch-container {
            text-align: center;
            margin-bottom: 40px;

            .alerts-switch {
                height: 46px;
                line-height: 46px;
                text-align: left;
                width: 70%;

                .slider {
                    &:before {
                        height: 44px;
                    }
                }
            }
        }

        .form-item {
            margin-top: 40px;

            .ant-slider {
                .ant-slider-rail {
                    height: 6px;
                }

                .ant-slider-dot {
                    display: none;
                }

                .ant-slider-track {
                    height: 6px;
                }

                .ant-slider-handle {
                    height: 24px;
                    width: 24px;
                    margin-top: -10px;
                    background-color: $color-primary;
                }

                .ant-slider-mark-text {
                    color: white;
                    white-space: nowrap;

                    &:last-child {
                        left: 97% !important
                    }
                }
            }
        }

        .ant-btn {
            margin-top: 40px;
        }

        .edit-alert {
            padding: 40px;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 100%;
        }
    }
}



.edit-alert {
    text-align: center;

    img {
        margin-bottom: 20px;
        width: 80px;
    }

    .ant-input {
        border-radius: 20px;
        background: #000;
        color: #fff;
        border-color: #000;
        margin-top: 20px;
    }

    .ant-btn {
        margin-bottom: 20px;
    }

    a {
        color: #fff;
    }
}

.ant-slider-tooltip {    
    z-index: 1040;

    .ant-tooltip-inner {
        background-color: transparent;
        box-shadow: none;
    }

    .ant-tooltip-arrow-content {
        background-color: transparent;
    }
}