@import '../../../assets/variables.scss';

.alerts-page {
    @media (max-width: 567px) {
        padding: 20px 10px;
    }

    .ant-alert {
        font-weight: 500;
        padding: 16px;

        .ant-alert-close-icon {
            .anticon {
                vertical-align: top;
            }
        }
    }

    .ant-card {
        border-radius: 6px;

        .h2 {
            color: #fff;
        }
        .h3 {
            color: $color-grey-dark;
        }

        .ant-btn {
            color: $color-accent1;
            border-color: $color-grey-light;
            border-width: 2px;
            border-radius: 4px;
            font-weight: 500;
        }

        .ant-table-container table {
            > thead {
                > tr th:first-child {
                    padding-left: 0px;
                }

                > tr {
                    > th {
                        background-color: #fff;
                        color: $color-grey-dark;
                        font-size: 12px;
                    }
                }
            }

            > tbody {
                > tr td:first-child {
                    padding-left: 0px;
                }

                td {
                    border-bottom-color: $color-grey-light;                    
                }
            }
        }

        //list is for mobile
        .ant-list-items {
            .ant-list-item:first-child {
                border-top: 1px solid $color-grey-light;
                margin-top: 20px;
            }

            .ant-list-item {
                border-color: $color-grey-light;
                padding: 21px 0;
            }
        }
    }

    .notifications {
        color: #fff;
        .ant-list-item-meta-avatar {
            margin: 0 16px;
            @media (max-width: 767px) { 
                margin: 10px 8px;
            }
        }

        .ant-list-item-meta-description {
            @media (max-width: 767px) { 
                display: flex;
            }
        }
    }

    .requests-list {
        .ant-list-item {
            background-color: $color-accent1;
            color: #fff;
            border-radius: 10px;
            margin-bottom: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 0;
            .ant-list-item-meta-avatar {
                margin-right: 8px;
                margin-left: 8px;
            }
            .ant-list-item-meta-title {
                color: #fff !important;
                font-size: 1.1em;
                font-weight: bolder;
            }
            .ant-list-item-meta-description {
                color: #fff !important;
            }
            .ant-btn-link {
                color: #fff !important;
            }
            
            @media (max-width: 1024px) {
                display: block;

                .request-description {
                    margin: 12px 12px 0 12px;
                }

                .ant-list-item-action {
                    text-align: right;
                }
            }
        }
    }

    .alerts-history {
        @media (max-width: 767px) {
            .ant-card-body {
                padding-top: 0;
            }
        }

        .ant-list-items {
            .ant-list-item {
                border-color: $color-grey-light;
                border-bottom: 1px solid $color-grey-light; 

                &:first-child {
                    border-top: none;
                }

                .ant-list-item-meta-avatar {
                    align-self: center;
                    color: $color-accent3;
                }
                .ant-list-item-meta-description {
                    color: $color-grey-dark;
                    font-weight: 500;
                }
            }
        }
    }

    .caption {
        font-size: 19px;
        color: black;
        font-family: 'Pulp Display';
        letter-spacing: 1px;
    }

    .ant-tabs-nav {
        margin: 0 0 -5px 0;

        &:before {
            border-bottom: none;
        }
    }

    .ant-tabs-nav-list {
        &:before {
            border-bottom: none;
        }
        .ant-tabs-ink-bar {
            display: none;
        }
        .ant-tabs-tab {
            padding: 0;
            margin: 0;

            .ant-tabs-tab-btn {
                color: #fff;
                border-radius: 10px 10px 0px 0px;
                padding: 12px 18px 15px 18px;
            }
            

            &.ant-tabs-tab-active {
                border-bottom: none;
                .ant-tabs-tab-btn {
                    color: #a2a2a2;
                    background-color: #fff;
                }
            }
        }
    }

    .announcements-history {
        .ant-list-item-meta-avatar {
            align-self: center;
            margin-bottom: 4px;
        }
    }

    .ant-tabs-nav-operations .ant-tabs-nav-more .anticon-ellipsis {
        color: #fff;
    }
}
