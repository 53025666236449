@import '../../../../assets/variables.scss';

.map-overlay-drawer-container {
  width: 145px;
  z-index: 1000;
  position: absolute;
  height: 100%;

  @media screen and (max-width: 967px) {
    padding-bottom: 79px;
  }
}

.map-overlay-drawer {
  bottom: 0px;
  position: relative;
  background-color: var(--color-primary-dark2-90);
  height: 100%;
  padding: 5px;
}

.map-overlay-drawer-heading {
  color: #fff;
  height: 16px;
  font-family: var(--seagull-body-font);
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  margin-bottom: 15px;
  margin-top: 10px;

  .map-layer-icons {
    font-size: 14px;
    top: 3px;
    color: #fff;
  }

  .map-layers-title {
    margin-top: -10px;
    margin-right: 5px;
  }
}

.ant-select {
  width: 100%;
  color: #fff;

  .ant-select-selector {
    border-radius: 15px;
    background-color: #000;
    border-color: #fff;
  }

  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: #fff;
  }
}

.ant-picker {
  width: fit-content;

  border-radius: 15px;
  background-color: #000;
  border-color: #fff;

  input,
  input::placeholder {
    color: #fff;
  }

  .ant-select-suffix {
    color: #fff;
  }
}

.omics-legend-wrapper, .bathy-legend-wrapper {
  .anticon {
    font-size: 14px;
  }

  span {
    color: #fff;
    font-family: var(--seagull-body-font), sans-serif;
    font-size: 12px;
  }
}

.omics-layer-filters-wrapper {
  margin: 0px 9px 16px 5px;
  list-style: none;
  padding: 0;
  color: #fff;

  li {
    margin: 10px 0px;
  }

  li:nth-child(1) {
    margin-bottom: 20px;
  }
}

.map-overlay-drawer {
  background-color: rgba(3, 31, 49, 0.7);
  height: 100%;
  padding: 5px;
}

.map-layer-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease-in-out 0.025s, opacity 0.05s ease-in-out;

  &.active {
    max-height: 340px;
    transition: max-height 0.2s ease-in-out, opacity 0.05s ease-in-out 0.2s;
    opacity: 1;
    overflow: hidden;
  }
}

.map-layer-card {
  align-self: start;
  background-color: #000;
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  stroke-width: 2;
  border-style: solid;
  margin-bottom: 10px;
  border-color: #444444;
  font-family: var(--seagull-body-font), sans-serif;

  &.active {
    border-color: #4cb1f7;
  }
}

.legend {
  height: 200px;
  width: 22px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  margin: 15px 15px 25px 15px;
}

.temp-legend {
  background: linear-gradient(#720202,
      #ac0606,
      #e81e76,
      #ff0026,
      $color-accent1,
      #fbbe1a,
      #fffe0c,
      hsl(73, 91%, 49%),
      hsl(88, 92%, 45%),
      #00d4b0,
      #1693a5,
      #a35b80,
      hsl(268, 48%, 26%));
}

.water-mag-legend {
  background: linear-gradient(#720202,
      #ac0606,
      #e81e76,
      #ff0026,
      $color-accent1,
      #fbbe1a,
      #fffe0c,
      hsl(73, 91%, 49%),
      hsl(88, 92%, 45%),
      #00d4b0,
      #1693a5);
}

.wind-mag-legend {
  background: linear-gradient(#720202,
      #ac0606,
      #e81e76,
      #ff0026,
      $color-accent1,
      #fbbe1a,
      #fffe0c,
      hsl(73, 91%, 49%),
      hsl(88, 92%, 45%),
      #00d4b0,
      #1693a5);
}

.temp-legend-item {
  height: 20px;
  width: 100%;
  border: $color-grey-light 1px solid;
  border-radius: 50%;
  margin-bottom: 10px;
}

.temp-legend-item .temp-legend-item-text {
  width: 65px;
  height: 25px;
  margin-left: 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 1px;
  z-index: 1;
}

.temp-logo {
  margin: 8px;
  width: 91px;
  height: 70px;
  font-family: var(--seagull-body-font), sans-serif;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);

  .close-button {
    font-size: 20px;
    color: #a3a3a3;
  }
}

.map-layer-title {
  margin: 8px;
  font-family: var(--seagull-body-font), sans-serif;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);

  .map-layer-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .anticon {
    font-size: 20px;
    color: #fff;

    .close-button {
      font-size: 20px;
    }
  }
}

.ant-select-dropdown {
  background-color: #152939;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  .custom-dropdown-title {
    color: #fff;
    display: flex;
    min-width: 200px;
    justify-content: space-between;
    font-family: var(--seagull-body-font), sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 16px 12px 20px 12px;
  }

  .custom-dropdown-filters {
    display: flex;
    justify-content: space-evenly;
    padding-left: 24px;
    padding-right: 24px;

    div {
      text-align: center;
      flex: 1;
      color: rgba(255, 255, 255, 0.8);

      div {
        display: inline;
      }

      &.active {
        font-weight: bold;
        color: rgba(255, 255, 255, 1);

        div {
          border-bottom: 2px solid #f67d28;
        }
      }
    }
  }

  .rc-virtual-list {
    margin: 16px 24px 20px 24px;

    .ant-select-item {
      color: #fff;
      padding: 0px;

      .ant-select-item-option-content {
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 8px;
        }
      }

      &.ant-select-item-option {

        &.ant-select-item-option-active,
        &.ant-select-item-option-selected {
          background-color: transparent;
        }
      }
    }
  }
}

.ant-picker-panel-container .ant-picker-panel {
  background-color: #000000;

  .ant-picker-header {
    color: $color-grey-default;

    button {
      color: $color-grey-default;
    }
  }

  .ant-picker-content {
    th {
      color: $color-grey-default;
    }

    .ant-picker-cell {
      &:hover {
        &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
          .ant-picker-cell-inner {
            background-color: $color-primary;
          }
        }
      }

      .ant-picker-cell-inner {
        height: 40px;
        line-height: 40px;
      }

      &:before {
        height: 40px;
      }
    }

    .ant-picker-cell-disabled {
      .ant-picker-cell-inner {
        color: $color-grey-default;
      }

      &:before {
        background-color: #000000;
      }
    }

    .ant-picker-cell-in-view {
      color: rgba(255, 255, 255, 0.85);

      &.ant-picker-cell-in-range {
        &:before {
          background-color: $color-primary;
        }
      }

      &.ant-picker-cell-range-start,
      &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: $color-accent3;
          min-width: 40px;
          border-radius: 50%;

          &:before {
            border: none;
          }
        }
      }

      &.ant-picker-cell-range-start {
        &:not(.ant-picker-cell-range-start-single) {
          &:before {
            background-color: $color-primary;
          }
        }
      }

      &.ant-picker-cell-range-end {
        &:not(.ant-picker-cell-range-end-single) {
          &:before {
            background-color: $color-primary;
          }
        }
      }
    }
  }

  &:nth-child(2) {
    display: none;
  }
}

.omics-layer-filters-wrapper {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: black !important;
    border-radius: 15px;
  }

  .ant-select .ant-select-arrow {
    rotate: 270deg;
  }
}