@import '../../assets/variables.scss';

.custom-multiple-select {
    border-color: $color-grey-light;
    color: $color-grey-dark;
    background-color: $color-grey-light;
    padding: 0;
  
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $color-grey-dark;
      }
    }
  
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: lighten(#ffffff, 40%);
  
        &:after {
          border-color: $color-grey-dark;
        }
      }
    }
    
    .custom-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;

        &.selected {
          background: #1890ff;
          color: #fff;
        }

        .text {
            padding-left: 10px;
            flex: 1;
        }
    }
}