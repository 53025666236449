@import '../../../../assets/mixins.scss';
@import '../../../../assets/variables.scss';

.group-settings {

    @include media-breakpoint(md, max) {
        padding: 20px 10px;
    }
    .header {
        display: flex;
        color: #fff;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;

        @include media-breakpoint(md, max) {
            margin-bottom: 0px;
            .anticon {
                margin-right: 10px;
            }
        }

        .h2 {
            display: flex;
        }

        a {
            color: #fff;
        }

        .ant-breadcrumb {
            margin-left: 20px;

            a, :not(.ant-btn) span {
                color: #fff;
                font-size: 19px;
            }

            > span:last-child a {
                color: #fff
            }
        }

        .ant-btn {
            border-radius: 4px;
            padding: 6.5px 20px;
        }

        .right-nav {
            a {
                font-weight: 500;
                margin-right: 20px;
            }

            .ant-btn {
                font-family: var(--seagull-heading-font);
            }
        }
    }

    .tabs-container {
        // height: 100%;

        .ant-tabs-card {
            // height: 100%;
            color: $color-grey-dark;

            .ant-tabs-nav {
                @include media-breakpoint(md, max) {
                    display: none;
                }

                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            background-color: transparent;
                            border: none;
                            color: #fff;
                            width: 150px;
                            justify-content: center;
                            padding-top: 16px;
                            border-top-left-radius: 20px;
                            border-top-right-radius: 20px;

                            &-active {
                                background-color: #fff;

                                .ant-tabs-tab-btn {
                                    color: $color-grey-default;
                                }
                            }
                        }

                        @include media-breakpoint(md, min) {
                            .ant-tabs-tab:not(:first-child) {
                                &.ant-tabs-tab-active + .ant-tabs-content {
                                    border-top-left-radius: 0;
                                }                                
                            }
                        }
                    }
                }
            }

            .ant-tabs-content {
                margin-top: -16px;        
                height: 100%;

                @include media-breakpoint(md, max) {
                    margin-top: 20px;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                >.ant-tabs-tabpane {
                    padding: 28px;
                    border-radius: 6px;                        
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: #fff;

                    @include scrollbar(transparent, var(--color-primary-dark3));
                    
                    @include media-breakpoint(md, min) {
                        &:first-child {
                            border-top-left-radius: 0;
                        }
                    }

                    @include media-breakpoint(md, max) {
                        padding: 20px;
                    }

                    p {
                        margin-bottom: 58px;
                    }
                }

                .h3 {
                    font-weight: 400;
                }

                .general-tab {
                    .right-pane {
                        background-color: $color-grey-light;
                        color: $color-grey-dark;
                        border-radius: 6px;
                        padding: 60px 20px;

                        @include media-breakpoint(md, max) {
                            padding: 20px;
                            margin-bottom: 30px;
                        }

                        .h3 {
                            display: flex;
                            align-items: center;

                            span {
                                margin-right: 5px;
                            }
                        }

                        .description {
                            padding-top: 20px;
                            padding-bottom: 20px;
                        }
                    }
                }

                .members-tab {
                    .requests-list {
                        border-bottom: 1px solid $color-grey-light;

                        .h3 {
                            padding-bottom: 40px;
                        }
    
                    }

                    .members-list {
                        margin-top: 20px;

                        .h3 {
                            padding-bottom: 18px;
                        }
                    }
                }
            }

            >.ant-tabs-nav::before {
                display: none;
            }
        }

        & [data-theme='compact'] {
            > .ant-tabs-card .ant-tabs-tab {
                background: transparent;
                border-color: transparent;
            }
        }
    }

    .ant-form-item {

        .ant-form-item-label {                       

            label {
                font-family: var(--seagull-heading-font);
                font-size: 15px;
                font-weight: 500;
                color: $color-grey-dark;
            }
        }

        .ant-input {
            border-radius: 4px;
        }

        &.editor-wrapper {
            .ant-form-item-control-input {
                overflow: hidden;
                .my-editor {
                    border: 4px solid #e6e6e6;
                    border-radius: 5px;

                    .bf-controlbar {
                        background-color: #e6e6e6;
                    }
                }
            }
        }
    }

    .members-filter {
        .middle-row {
            // border-bottom: 1px solid #fff;
            padding-bottom: 20px;

            .ant-select {
                font-size: 14px;

                .ant-select-selector {
                    border-radius: 4px;
                }

                .ant-select-arrow {
                    color: $color-grey-dark;
                    font-size: 14px;
                }                

                .ant-select-selector {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    .ant-select-selection-placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }

                @include media-breakpoint(md, max) {
                    &.sorter {
                        .ant-select-selector {
                            color: #fff;
                        }
                    }
                }
            }

            .ant-input-affix-wrapper {
                color: $color-grey-dark;
                background-color: $color-grey-light;

                .ant-input {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    &::placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}