@import '../../assets/variables.scss';

amplify-authenticator {
  --container-height: auto;
  --border-radius: 0;
  --amplify-font-family: var(--seagull-body-font), sans-serif;
  --box-shadow: 0;
}

amplify-sign-in,
amplify-sign-up,
amplify-forgot-password {
  --padding: 5px 40px 35px 40px;

  width: 100%;
}

.auth-drawer {
  &.ant-drawer-open {
    top: 78px;
    height: calc(100% - 78px);
    
    @media(max-width: 767px) {
      top: 66px;
      height: calc(100% - 66px);
    }
  }
    

  .authenticator-drawer-header {
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-content-wrapper {
    align-items: center;
  }

  .ant-drawer-wrapper-body {
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 58px;
  }

  .ant-drawer-content {
    background: transparent;
    // display: flex;
    // flex-direction: column;
  }

  .ant-drawer-body {
    padding: 0;
    max-width: 460px;
    width: calc(100% - 30px);
  }
}

.ant-notification {
  z-index: 1200;
}