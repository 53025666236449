@import '../../assets/variables.scss';

.top-bar {
  display: flex;
  height: 58px;
  overflow: hidden;
  padding: 0 30px;
  padding-left: 0;
  z-index: 999;

  @media (max-width: 767px) {
    height: 46px;
    line-height: 52px;
    background-color: var(--color-primary-dark2);
  }

  .megaphoneAlignment {
    translate: 0 -3px;
  }

  .mobile-menu {
    display: none;
    padding-left: 10px;
    padding-top: 7px;
    font-weight: 500;

    .anticon {
      color: #fff;
    }

    .ant-btn {
      background-color: transparent;
      border: none;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  &.bg-transparent {
    // @media (max-width: 567px) {
    //   background-color: rgba(var(--color-primary-dark2), 0.9);
    // }

    .logo,
    .ant-menu {
      background-color: transparent;
    }
  }

  .ant-menu-horizontal {
    .ant-menu-item {
      a {
        color: #fff
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: transparent;
      }
    }
  }

  .top-bar-left {
    font-family: var(--seagull-heading-font);
    padding-left: 20px;
    display: block;

    .ant-menu {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .open-usersnap-feedback {
      &:focus {
        color: white !important;
      }
    }
  }

  .top-bar-right {
    margin-left: auto;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;

    .notifications {
      padding-left: 13px;
    }

    a {
      color: #fff;
    }
    
    .searchbox {
      font-family: var(--seagull-body-font);
      background-color: #000;
      border: 1px solid $color-grey-default;
      border-radius: 40px;
      box-shadow: 2px 2px 12px 2px rgba(0,7,130,0.12);
      width: 325px;

      input {
        background-color: #000;
        border-radius: 40px;
        color: #fff;
        padding-left: 6px;

        &::placeholder {
          color: #fff;
        }
      }

      .ant-input-suffix {
        padding: 5px 0;

        button {
          background-color: $color-accent1;
          color: $color-button-text;
          border: none;
          min-width: 24px;
          width: 24px;
          height: 24px;
          padding: 0;
        }
      }
    }
  }
}

.profile-menu {
  background-color: #001529;
  padding: 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .ant-dropdown-menu-item {
    padding: 10px;

    &:hover {
      background-color: #001529;
    }

    a {
      color: #fff;
    }

    &:not(:last-child) {
      border-bottom: #fff solid 1px;
    }
  }  

  .ant-dropdown-menu-item-divider {
    margin: 5px;
  }
}

.mobile-nav {
  .ant-menu {
    background: none;
    color: #fff;    
    border-right: none;

    a,
    a:hover {
      color: #fff;
    }

    .ant-menu-item-selected {
      background: none;
      color: #fff;
    }
  }

  .ant-menu-inline {

    .ant-menu-item {          
      padding-left: 0 !important;    
      
      &:after {
        border: none;
      }
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-selected {
        color: #fff;
      }
      
      .ant-menu-submenu-title {
        padding-left: 0 !important;

        .ant-menu-submenu-arrow {
          display: none;
        }
      }

      .ant-menu {          
        padding-left: 16px;

        .ant-menu-item {
          margin-top: 5%;
        }
      }
    }
  }

  .top-menu {
    font-size: 21px;
    border-bottom: 1px solid #f0f0f0;

    // .ant-menu-item {
    //   margin-top: 22%;
    //   &:last-child {
    //     margin-bottom: 22%;
    //   }
    // }

    // margin-bottom: 20%;
  }

  .ant-drawer-header {
    padding: 16px 8px;

    .ant-drawer-close {
      padding: 12px
    }

    .logo {
      left: 0
    }
  }

  .ant-drawer-header,
  .ant-drawer-content {
    background-color: var(--color-primary-dark2);
    border: none;
  }

  .ant-drawer-close {
    right: inherit;
    color: #fff;
    // font-size: 28px;
    margin-top: 54px;
    // ^ensure menu close button is visible below survey banner
  }

  .ant-drawer-body {
    padding-left: 18%;
    padding-right: 23%;
  }
}

.preferences-menu {
  padding: 10px 15px;
  margin-top: -7px; // removes gap below button
  max-width: 150px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .ant-space-item:not(:last-child) {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }

  .group-heading {
    color: #fff;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .ant-radio-wrapper {
    color: #fff;
    font-size: 12px;
    display: block;
    padding-left: 8px;
    position: relative;
    margin-bottom: 2px;
  }

  .ant-radio {
    display: none;
  }

  .ant-radio-wrapper-checked::before {
    content: '\2713';
    position: absolute;
    left: 0;
  }
}
.custom-dropdown-overlay {
  top: 78px !important;
}

.notifications-drawer {
  .ant-typography {
    a {
      color: $color-accent1;
    }
  }
}

