@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.groups {
    height: 100%;

    .groups-filter {
        padding: 20px 20px 0;

        @include media-breakpoint(md, max) {
            background-color: var(--color-primary-dark2);
            padding: 20px 10px 0;
        }
                
        .top-row {
            display: flex;
            justify-content: space-between;

            .ant-btn {
                background: #000;
                color: #fff;
                border: none;
                border-radius: 4px;
                padding: 10px 20px;
                height: 40px;
            }
        }

        .middle-row {
            // border-bottom: 1px solid #fff;
            padding-bottom: 20px;

            .ant-select {
                font-size: 14px;

                .ant-select-selector {
                    border-radius: 4px;
                }

                .ant-select-arrow {
                    color: $color-grey-dark;
                    font-size: 14px;
                }                

                .ant-select-selector {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    .ant-select-selection-placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }

                @include media-breakpoint(md, max) {
                    &.sorter {
                        .ant-select-selector {
                            color: #fff;
                        }
                    }
                }
            }

            .ant-input-affix-wrapper {
                color: $color-grey-dark;
                background-color: $color-grey-light;

                .ant-input {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    &::placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }
            }
        }

        .ant-tabs-top {
            .ant-tabs-nav::before {
                border-bottom: none;
            }
        }

        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                color: #fff
            }
        }

        .ant-tabs-ink-bar {
            background: $color-accent1;
            color: $color-button-text;
        }
    }

    .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--color-primary-dark2);
        padding: 20px;
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        @include scrollbar(transparent, var(--color-primary-dark2));

        .ant-table {
            background-color: var(--color-primary-dark2);
            color: #fff;

            .ant-table-thead {
                tr {
                    th {
                        background-color: var(--color-primary-dark2);
                        color: #fff;
                        border-bottom: none;
                    }

                    &.ant-table-row-hover:not(.ant-table-expanded-row),
                    &:hover:not(.ant-table-expanded-row) {
                        td {
                            background: unset; //Change the existing color to `unset`
                        }
                    }
                }
            }

            .ant-table-tbody{
                tr {
                    &.ant-table-row-hover:not(.ant-table-expanded-row),
                    &:hover:not(.ant-table-expanded-row) {
                        td {
                            background: unset; //Change the existing color to `unset`
                        }
                    }
                    td {
                        a {
                            color: #fff;
                        }
                        
                        &.ant-table-column-sort {
                            background-color: var(--color-primary-dark2);
                        }
                    }

                }
            }

            .ant-table-column-sorter {
                margin-left: 6px;
            }
        }

        .group-title {
            padding: 30px 0;
        }
    }
}

.sidebar {
    .ant-menu {
        .ant-menu-item-selected {
            &.data-console-groups {
                background-color: var(--color-primary-dark2);

                &::before {
                    background: radial-gradient(circle at bottom left, $color-primary 20px, var(--color-primary-dark2) 21px);
                }

                &::after {
                    background: radial-gradient(circle at top left, $color-primary 20px, var(--color-primary-dark2) 21px);
                }
            }
        }
    }
}