@import '../../assets/variables.scss';

.research-workspace {
  position: relative;

  .seagull-loading {
    top: 100px !important;
  }

  svg:not(:root) {
    overflow: visible;
  }
}

.research-workspace-drawer {
  border-radius: 0 10px 10px 0;
}
.ant-drawer-content {
  border-radius: 0 10px 10px 0;
}

.ant-drawer-title {
  color: var(--seagull-primary-color);
  font-size: 20px;
  font-weight: 600;
}

.titleFont {
  color: $color-white;
  font-family: 'PulpDisplay-SemiBold', system-ui;
  font-size: 28px;
  margin-top: -5px;
  font-weight: 600;
  letter-spacing: 1px;
}

.betaSign {
  font-family: Work Sans;
  font-style: italic;
  background-color: $color-black;
  border: 3px solid $color-black;
  border-radius: 10px;
  font-size: 14px;
  padding: 0px 7px;
  font-weight: 400;
  margin-left: 10px;
}
