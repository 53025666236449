.join-request {
  .ant-avatar {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .ant-list-item-meta-title {
    margin: 0;
  }
}