// Colors
$color-primary: var(--color-primary);
$color-primary-dark: var(--color-primary-dark);
$color-primary-dark2-70:  var(--color-primary-dark2-70);
$color-primary-dark2-80:  var(--color-primary-dark2-80);
$color-primary-dark2-90:  var(--color-primary-dark2-90);
$color-success: var(--color-success);
$color-danger: var(--color-danger);

$color-white: #ffffff;
$color-black: #000000;

$color-grey-default: var(--color-grey-default);
$color-grey-light: var(--color-grey-light);
$color-grey-dark: var(--color-grey-dark);

$color-accent1: var(--color-accent1);
$color-accent2: $color-success;
$color-accent3: var(--color-accent3);
$color-button-text: var(--color-button-text);