@import "../../assets/mixins.scss";
@import "../../assets/variables.scss";

.overlays {
  .map-legend {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: var(--color-primary-dark2);
    width: 40px;
    border-radius: 6px;
    margin-top: 20px;
    
    .anticon {
       margin: 3px 0;
       &:first-child {
          margin-top: 6px;
       }
       &:last-child {
          margin-bottom: 6px;
       }
    }
  }

  .mapboxgl-popup {
    will-change: auto;
    position: absolute;
    z-index: 10;

    .mapboxgl-popup-content {
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      padding: 10px 30px;
      border-radius: 10px;
    }

    &.omics-popup {
      .mapboxgl-popup-content {
        background: rgba(0, 0, 0, .7);
      }
    }
  }
  
  .mapboxgl-marker {
    position: relative;
    z-index: 1;
  }

  .pin-label {
    display: inline;
    position: absolute;
    top: 11px;
    left: 0;
    transform: translateX(-50%);
    text-align: center;
    background:rgba(0, 0, 0, .45);
    color: #fff;
    width: auto;
    line-height: 1;
    font-size: 13px;
    padding: 3px;
    border-radius: 4px;
    z-index: 1;
  }

  .sampling_location .pin-label {
    top: 2px;
  }

  div[class*="mapboxgl-popup-anchor-top"] {
    margin-top: 20px;

    .mapboxgl-popup-tip {
      border-bottom-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  div[class*="mapboxgl-popup-anchor-bottom"] {
    margin-top: -20px;

    .mapboxgl-popup-tip {
      border-top-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  div[class*="mapboxgl-popup-anchor-bottom-right"] {
    margin-top: -20px;

    .mapboxgl-popup-content {
      border-bottom-right-radius: 0 !important;
    }

    .mapboxgl-popup-tip {
      border-bottom-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  div[class*="mapboxgl-popup-anchor-bottom-left"] {
    margin-top: -20px;

    .mapboxgl-popup-content {
      border-bottom-left-radius: 0 !important;
    }

    .mapboxgl-popup-tip {
      border-bottom-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  div[class*="mapboxgl-popup-anchor-top-left"] {
    margin-top: 20px;

    .mapboxgl-popup-content {
      border-top-left-radius: 0 !important;
    }

    .mapboxgl-popup-tip {
      border-top-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  div[class*="mapboxgl-popup-anchor-top-right"] {
    margin-top: 20px;

    .mapboxgl-popup-content {
      border-top-right-radius: 0 !important;
    }

    .mapboxgl-popup-tip {
      border-top-color: rgba(0, 0, 0, 0.4) !important;

      @include media-breakpoint(md, max) {
        border-color: transparent;
      }
    }
  }

  .geolocate-control {
    bottom: 140px;
    right: 20px;

      @include media-breakpoint(md, max) {
         left: 10px;
         right: 88%;
         top: 195px;
      }

      .mapboxgl-ctrl {
         background-color: rgba(0, 0, 0, 0.00);
         border-radius: 5px;
         width: 40px;
         height: 40px;

         button {
            width: 41px;
            height: 40px;
            border-radius: 5px;
            background-color: var(--color-primary-dark2-70);
            -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);

            &.mapboxgl-ctrl-geolocate {
               .mapboxgl-ctrl-icon {
                  background-color: rgba(3, 31, 49, 0.70);
                  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' viewBox='0 0 46 46'%3E%3Cg id='Current-Location' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icon-24px' transform='translate(13.000000, 13.000000)'%3E%3Cpolygon id='Shape' points='0 0 21 0 21 21 0 21'%3E%3C/polygon%3E%3Cpath d='M10.5,7 C8.56625,7 7,8.56625 7,10.5 C7,12.43375 8.56625,14 10.5,14 C12.43375,14 14,12.43375 14,10.5 C14,8.56625 12.43375,7 10.5,7 L10.5,7 Z M11.375,2.6775 L11.375,1.88165036 C11.375,1.32569272 10.9334011,0.875 10.3773136,0.875 L10.6226864,0.875 C10.0716794,0.875 9.625,1.32984686 9.625,1.88165036 L9.625,2.6775 C5.97625,3.08 3.08,5.97625 2.6775,9.625 L1.88165036,9.625 C1.32569272,9.625 0.875,10.0665989 0.875,10.6226864 L0.875,10.3773136 C0.875,10.9283206 1.32984686,11.375 1.88165036,11.375 L2.6775,11.375 C3.08,15.02375 5.97625,17.92 9.625,18.3225 L9.625,19.1183496 C9.625,19.6743073 10.0665989,20.125 10.6226864,20.125 L10.3773136,20.125 C10.9283206,20.125 11.375,19.6701531 11.375,19.1183496 L11.375,18.3225 C15.02375,17.92 17.92,15.02375 18.3225,11.375 L19.1183496,11.375 C19.6743073,11.375 20.125,10.9334011 20.125,10.3773136 L20.125,10.6226864 C20.125,10.0716794 19.6701531,9.625 19.1183496,9.625 L18.3225,9.625 C17.92,5.97625 15.02375,3.08 11.375,2.6775 Z M10.5,16.625 C7.11375,16.625 4.375,13.88625 4.375,10.5 C4.375,7.11375 7.11375,4.375 10.5,4.375 C13.88625,4.375 16.625,7.11375 16.625,10.5 C16.625,13.88625 13.88625,16.625 10.5,16.625 L10.5,16.625 Z' id='Shape' fill='white'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                  opacity: 1;
               }
           }

           &:hover {
             background-color: rgba(3, 31, 49, 0.70);
             .hover-text {
             display: inline-block;
           }
          }
         }
      }
   }

  .platform-event-controls {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: var(--color-primary-dark2);
    width: 40px;
    border-radius: 6px;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .zoom-controls {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #fff;

    .mapboxgl-ctrl {
      button {
        &.mapboxgl-ctrl-zoom-in {
          .mapboxgl-ctrl-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' preserveAspectRatio='none' viewBox='0 0 24 24' height='15' width='15' fill='white'%3E%3Cg%3E%3Cdefs%3E%3C/defs%3E%3Crect display='none' fill='white' width='24' height='24'%3E%3C/rect%3E%3Crect id='_x3C_Slice_x3E__126_' display='none' fill='white' width='24' height='24'%3E%3C/rect%3E%3Cpolygon fill-rule='evenodd' clip-rule='evenodd' points='14,10 14,0 10,0 10,10 0,10 0,14 10,14 10,24 14,24 14,14 24,14 24,10 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
          }
        }
        &.mapboxgl-ctrl-zoom-out {
          .mapboxgl-ctrl-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' preserveAspectRatio='none' viewBox='0 0 24 24' width='15' height='15'%3E%3Cg%3E%3Cdefs%3E%3C/defs%3E%3Crect display='none' fill='%23FFA400' width='24' height='24' style='fill: rgb(255, 255, 255);'%3E%3C/rect%3E%3Crect id='_x3C_Slice_x3E__126_' display='none' fill='none' width='24' height='24' style='fill: rgb(255, 255, 255);'%3E%3C/rect%3E%3Crect y='10' fill-rule='evenodd' clip-rule='evenodd' width='24' height='4' style='fill: rgb(255, 255, 255);'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }

    .mapboxgl-ctrl-icon {
      width: 43px;
      height: 50px;
      background-color: var(
        --color-primary-dark2
      ); // rgba(var(--color-primary-dark2), .8);
      color: #fff;

      &:hover {
        background-color: var(
          --color-primary-dark2
        ); //rgba(var(--color-primary-dark2), .8);
      }
      &.mapboxgl-ctrl-zoom-out {
        border-top: none;
      }
    }
  }

  .mapboxgl-marker .clicked {
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      background: rgba(0, 0, 0, 0.2);
      display: block;
      border-radius: 50%;
      z-index: -1;
    }
  }
}

.socialShare {
  .mapboxgl-control-container {
    .mapboxgl-ctrl-bottom-right {
      // we want to move the 'i' icon to the top right
      top: 0;
      right: 0;

      .mapboxgl-ctrl-attrib.mapboxgl-compact {
        min-height: 23px;
      }
    }
  }

  .zoom-controls {
    .mapboxgl-ctrl-group {
      background: transparent;
      &:not(:empty) {
        box-shadow: none;
      }

      .mapboxgl-ctrl-icon {
        background-color: rgba(4, 30, 51, 0.8);

        &.mapboxgl-ctrl-zoom-in,
        &.mapboxgl-ctrl-zoom-out {
          height: 43px;
          .mapboxgl-ctrl-icon {
            height: 43px;
          }
        }

        &.mapboxgl-ctrl-zoom-in {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &.mapboxgl-ctrl-zoom-out {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}

.map-dataset-detail-drawer {
  .ant-drawer-body {
    //Apply padding left for large screens
    @include media-breakpoint(md, min) {
      // padding-left: 6%;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 100px;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        0deg,
        #0f395a 23%,
        rgba(15, 57, 89, 0) 100%
      );
      width: 100%;
      height: 4em;
    }

    .webcam {
      a {
        display: flex;
        align-items: center;
        color: $color-grey-light;
      }
    }
  }

  .ant-drawer-footer {
    border-top: none;
    font-weight: 500;

    //Apply padding left for large screens
    @include media-breakpoint(md, min) {
      padding-left: 6%;
    }

    background-color: rgba(#0f395a, 0.9);

    button {
      border: none;
      border-radius: 4px;
    }
  }

  .dataset-message {
    margin-top: 20px;
    padding: 5px 29px;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    display: flex;
    align-items: center;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.mobile-dataset-summary-drawer {
  position: absolute;
  &.ant-drawer-bottom.ant-drawer-open {
    height: auto;
  }

  .ant-drawer-body {
    padding: 10px !important;
    height: 100px !important;
  }

  .ant-drawer-footer {
    font-weight: 500;

    button {
      border: none;
      border-radius: 4px;
    }
  }
}

.day-slider {
  top: 28px;
}

.slider-track {
  top: 8px;
  height: 8px;
  background-color: #ccc;
  border-style: solid;
  border-color: #fff;
  border-width: 1px;
  border-radius: 0px 10px 10px 0px;
}

.slider-track-0 {
  top: 8px;
  height: 8px;
  background-color: $color-accent1;
  border-style: solid;
  border-color: #fff;
  border-width: 1px;
  border-radius: 10px 0px 0px 10px;
}

.slider-thumb {
  height: 40px;
  top: -20px;
  outline: none;
}

.day-marks {
  cursor: pointer;
  top: 12px;
  width: 1.5px;
  height: 26px;
  background-color: #fff;
}

.thumb-circle {
  -webkit-appearance: none;
  background: $color-accent1;
  height: 22px;
  width: 22px;
  position: absolute;
  z-index: 10;
  top: 20px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.slider-container {
  position: absolute;
  z-index: 11;
  width: calc(100% - 302px);
  display: flex;
  justify-content: center;
  bottom: 0px;
  left: 151px;
}

.slider-background {
  position: relative;
  bottom: 0;
  width: 90%;
  height: 93px;
  background-color: rgba(3, 31, 49, 0.7);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 55px;
}

.slider-parent {
  position: relative;
  width: 85%;
}
.slider-label-container {
  color: #fff;
  font-family: var(--seagull-heading-font), serif;
}
.slider-label {
  position: absolute;
  text-align: center;
  left: -30px;
  width: 80px;
  top: 0px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 1em;
  color: #fff;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid white;
  float: right;
  left: 28px;
  bottom: 1px;
}
.arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid white;
  float: left;
  left: -17px;
  bottom: 1px;
}

.play-button {
  position: absolute;
  left: 25px;
  top: 27px;
  cursor: pointer;
  &:hover {
    fill: #afacac;
  }
}

input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  height: 6px;
  width: 100%;
  top: 27px;
  cursor: pointer;
  border-radius: 10px;
  background: -webkit-repeating-linear-gradient(
      90deg,
      #777,
      #777 1px,
      transparent 1px,
      transparent 40px
    )
    no-repeat 50% 50%;
  background: -moz-repeating-linear-gradient(
      90deg,
      #777,
      #777 1px,
      transparent 1px,
      transparent 40px
    )
    no-repeat 50% 50%;
  background: repeating-linear-gradient(
      90deg,
      #777,
      #777 1px,
      transparent 1px,
      transparent 40px
    )
    no-repeat 50% 50%;
  background-size: 122px 25px;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 6px;
  background: #afacac;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   background: $color-accent1;
   color: $color-button-text;
   height: 22px;
   width: 22px;
   position: relative;
   z-index: 10;
   top:-8px;
   border: 1px solid #ffffff;
   border-radius: 50%;
}
input[type="range"]::-moz-range-track {
  height: 6px;
  background: #afacacff;
  border-radius: 10px;
}
input[type="range"]::-moz-range-progress {
   background-color: $color-accent1;
   color: $color-button-text;
   height: 6px;
   border-radius: 10px;
}
input[type="range"]::-moz-range-thumb {
   background: $color-accent1;
   color: $color-button-text;
   height: 20px;
   width: 20px;
   position: relative;
   z-index: 10;
   border-radius: 50%;
   border: 1px solid #ffffff;
}
.day-container {
  position: relative;
  bottom: -46px;
  width: 100%;
  height: 24px;
  stroke-width: 1;
  overflow: hidden;
  padding-left: 6px;
}
.day-item-today {
  position: relative;
  width: 20%;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 14px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  text-align: center;
  float: left;
  cursor: pointer;
  &:hover {
    color: #afacac;
  }
}
.day-item-full {
  position: relative;
  width: 20%;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 14px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  text-align: center;
  float: left;
}
.day-item-half {
  position: relative;
  width: 63px;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 14px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  float: left;
  text-align: center;
}
.day-divider {
  position: relative;
  width: 4px;
  height: 17px;
  top: -5px;
  font-family: "PulpDisplay-Bold", serif;
  font-size: 17px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  float: left;
  margin: auto;
}
.forecast-data-disclaimer {
  position: absolute;
  bottom: 10px;
  height: 17px;
  font-family: var(--seagull-body-font);
  font-size: 12px;
  color: #ffffff;
  font-style: italic;
  text-decoration: rgb(255, 255, 255);
}
.contour-popup {
  width: 124px;
  height: 84px;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #000000;
  background-size: cover;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  z-index: 999;
}
.contour-popup-title {
  width: auto;
  height: auto;
  font-family: var(--seagull-heading-font), serif;
  font-size: 22px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
}
.contour-popup-text {
  width: auto;
  height: auto;
  font-family: var(--seagull-body-font);
  font-size: 14px;
  text-align: center;
  color: $color-grey-light;
  text-decoration: rgb(230, 230, 230);
}

.bathy-popup-text {
  width: auto;
  height: auto;
  font-family: var(--seagull-body-font);
  font-size: 14px;
  text-align: left;
  color: $color-grey-light;
  text-decoration: rgb(230, 230, 230);
}

.water-mag-legend {
  z-index: 12;
  height: 260px;
  width: 22px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  background: linear-gradient(
    #720202,
    #ac0606,
    #e81e76,
    #ff0026,
    $color-accent1,
    #fbbe1a,
    #fffe0c,
    hsl(73, 91%, 49%),
    hsl(88, 92%, 45%),
    #00d4b0,
    #1693a5
  );
}
.wind-mag-legend {
  z-index: 12;
  height: 260px;
  width: 22px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  background: linear-gradient(
    #720202,
    #ac0606,
    #e81e76,
    #ff0026,
    $color-accent1,
    #fbbe1a,
    #fffe0c,
    hsl(73, 91%, 49%),
    hsl(88, 92%, 45%),
    #00d4b0,
    #1693a5
  );
}
.mobile-slider-container {
  position: absolute;
  z-index: 11;
  width: 100%;
  display: flex;
  justify-content: center;
}
.mobile-slider-background {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 79px;
  padding-left: 55px;
  background-color: rgba(3, 31, 49, 0.7);
}
.mobile-slider-parent {
  position: relative;
  width: 85%;
}

.mobile-slider-label {
  position: absolute;
  float: left;
  left: -16px;
  top: 0px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  width: 50px;
}
.mobile-play-button {
  position: absolute;
  left: 15px;
  top: 27px;
  cursor: pointer;
}
.mobile-day-container {
  position: relative;
  padding-left: 6px;
  bottom: -50px;
  width: 100%;
  height: 24px;
  stroke-width: 1;
}
.mobile-day-item-today {
  position: relative;
  width: 33%;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 12px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  text-align: center;
  float: left;
  cursor: pointer;
}
.mobile-day-item-full {
  position: relative;
  width: 33%;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 12px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  text-align: center;
  float: left;
}
.mobile-day-item-half {
  position: relative;
  width: 40px;
  height: 17px;
  font-family: var(--seagull-heading-font), serif;
  font-size: 12px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  float: left;
  left: 5px;
  text-align: center;
}
.mobile-day-divider {
  position: relative;
  width: 2px;
  height: 17px;
  top: -5px;
  font-family: "PulpDisplay-Bold", serif;
  font-size: 17px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  float: left;
  margin: auto;
}

.mobile-temp-legend-container {
  position: absolute;
  height: 110px;
  width: 30px;
  left: 0px;
  bottom: 130px;
  border-radius: 4px;
  display: flex;
  background: var(--color-primary-dark2);
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.mobile-temp-legend {
  position: absolute;
  height: 105px;
  width: 16px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  background: linear-gradient(
    #720202,
    #ac0606,
    #e81e76,
    #ff0026,
    $color-accent1,
    #fbbe1a,
    #fffe0c,
    hsl(73, 91%, 49%),
    hsl(88, 92%, 45%),
    #00d4b0,
    #1693a5,
    #a35b80,
    hsl(268, 48%, 26%)
  );
}
.mobile-water-legend {
  position: absolute;
  height: 105px;
  width: 16px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  background: linear-gradient(
    #720202,
    #ac0606,
    #e81e76,
    #ff0026,
    $color-accent1,
    #fbbe1a,
    #fffe0c,
    hsl(73, 91%, 49%),
    hsl(88, 92%, 45%),
    #00d4b0,
    #1693a5 //#A35B80,
    //hsl(268, 48%, 26%)
  );
}
.mobile-wind-legend {
  position: absolute;
  height: 105px;
  width: 16px;
  border: $color-grey-light 1px solid;
  border-radius: 15px;
  background: linear-gradient(
    #720202,
    #ac0606,
    #e81e76,
    #ff0026,
    $color-accent1,
    #fbbe1a,
    #fffe0c,
    hsl(73, 91%, 49%),
    hsl(88, 92%, 45%),
    #00d4b0,
    #1693a5 //#A35B80,
    //hsl(268, 48%, 26%)
  );
}
.mobile-temp-legend-item {
  height: 15px;
  width: 15px;
  border: $color-grey-light 1px solid;
  border-radius: 50%;
}
.mobile-temp-legend-item-text {
  visibility: visible;
  font-size: smaller;
  width: 55px;
  height: 20px;
  border-radius: 4px;
  left: 120%;
  background: var(--color-primary-dark2);
  color: #ffffff;
  text-align: center;
  position: absolute;
  margin-top: -5px;
}

.socialShare {
  .map-layer-card {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .close-button {
    display: none;
  }

  .temp-legend-item {
    .temp-legend-item-text {
      display: block;
      text-align: left;
    }
  }

  #sharing-map-overlay-legend {
    height: 100%;
  }

  @include media-breakpoint(sm, max) {
    top: 0;

    .ant-modal-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    #sharing-map-overlay-legend {
      width: 108px;
    }

    .overlay-label {
      div {
        display: inline-block;
      }
    }

    .map-layer-card {
      height: 100%;
    }

    .map-layer-title {
      font-size: 13px;
      margin-bottom: 0;
    }

    .legend {
      height: 104px;
      width: 15px;
      margin: 10px 15px 25px 10px;
      font-size: 13px;
    }

    .temp-legend-item {
      height: 12px;
      margin-bottom: 3px;

      .temp-legend-item-text {
        margin-left: 28px;
        line-height: 1;
      }
    }
  }
}

.webcam-feed-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;

    .ant-modal-close {
      margin-right: -30px;
      top: 5px;

      .ant-modal-close-x {
        font-size: 33px;
        color: #fff;
      }
    }
  }
}

.omics-selected-filters-container {
  position: absolute;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 0px;
  left: 190px;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 12px 12px;


  p {
    color: #fff;
    font-family: Work Sans;
    font-size: 12px;
    margin-bottom: 0;
  }

  .anticon {
    color: #fff;
    font-size: 20px;
    margin-right: 8px;
  }
}
