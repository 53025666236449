@import '../../../assets/variables.scss';

.console-compare {
    padding-bottom: 20px;
    
    .ant-collapse {
        .ant-collapse-content {
            .ant-row {
                .ant-col:not(:first-child) {
                    text-transform: capitalize
                }
            }
        }
    }
}