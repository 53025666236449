@import '../../assets/mixins.scss';
@import '../../assets/variables.scss';

.data-console {
    height: 100%;

    .orange {
        color: orange;
    }

    .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @include media-breakpoint(md, max) {
            padding: 10px;
        }

        .data-list {
            flex: 1;
            overflow-y: scroll;
            overflow-x: scroll;

            @include scrollbar(transparent, var(--color-primary-dark3));

            th.ant-table-cell:last-of-type {
                text-align: center;
            }

            td.ant-table-cell:last-of-type {
                text-align: center;
            }

           tr td:first-of-type, tr td:nth-of-type(2) {
                text-overflow: ellipsis;
                display: revert;
                overflow: hidden;
            }

            .full-width {
                width: 100%;
                white-space: pre;
            }

            .fivehundred-width {
                width: 500%;
                max-width: 500%;
            }

            .tile-view {
                padding-top: 20px;
                padding-right: 10px;

                .ant-card {
                    border: none;
                    color: #fff;
                    background-color: #082434;
                }
            }
        }

        //Add this on desktop and above
        @media (min-width: 1170px) {
            // border-radius: 10px;
        }

        //mobile table style
        @media (max-width: 767px) {

            background-color: var(--color-primary-dark);
            position: relative;
            z-index: 20;

            .ant-card-body {
                background-color: #082434;
                border-radius: 15px;
            }


            .ant-table {
                background-color: var(--color-primary-dark2);
                color: #fff;

                .ant-table-thead {
                    position: sticky;
                    top: 0;
                    background-color: var(--color-primary-dark2);
                    z-index: 10;

                    td {
                        text-decoration: underline;
                    }

                    tr {
                        th {
                            background-color: #081c2c;
                            color: #fff;
                            border-bottom: none;
                        }
                    }
                }
            }

            tbody td.ant-table-cell-fix-left {
                text-decoration: underline;
            }

            tbody tr:nth-child(even) {
                background-color: #183c54;
            }

            tbody tr:nth-child(even) td.ant-table-cell-fix-left {
                background-color: #183c54;
            }

            tbody tr.ant-table-row:nth-child(odd) {
                background-color: var(--color-primary-dark2)
            }

            tbody tr.ant-table-row:nth-child(odd) td.ant-table-cell-fix-left {
                background-color: var(--color-primary-dark2)
            }

            .ant-table-tbody>tr.ant-table-row:hover:nth-child(even)>td {
                background-color: #183c54
            }

            .ant-table-tbody>tr.ant-table-row:hover:nth-child(odd)>td {
                background-color: var(--color-primary-dark2)
            }

            tbody td.ant-table-column-sort {
                background: none !important;
            }

            div.ant-table-column-sorters span {
                white-space: nowrap;
            }

            .platforms-filter .top-row {
                display: flex;
                justify-content: flex-start;
                gap: 10px;
            }

        }

        // table desktop & tablet style 
        @media (min-width: 767px) {
            .ant-table {
                background-color: var(--color-primary-dark2);
                color: #fff;

                .ant-table-thead {
                    position: sticky;
                    top: 0;
                    background-color: var(--color-primary-dark2);
                    z-index: 10;

                    tr {
                        th {
                            background-color: var(--color-primary-dark2);
                            color: #fff;
                            border-bottom: none;
                        }

                        &.ant-table-row-hover:not(.ant-table-expanded-row),
                        &:hover:not(.ant-table-expanded-row) {
                            td {
                                background: unset; //Change the existing color to `unset`
                            }
                        }
                    }
                }

                .ant-table-tbody {
                    tr {

                        &.ant-table-row-hover:not(.ant-table-expanded-row),
                        &:hover:not(.ant-table-expanded-row) {
                            td {
                                background: unset; //Change the existing color to `unset`
                            }
                        }

                        td {
                            &.ant-table-column-sort {
                                background-color: var(--color-primary-dark2);
                            }
                        }

                    }
                }

                .ant-table-column-sorter {
                    margin-left: 0px;
                }
            }
        }
    }

    .platforms-filter {
        padding: 20px 20px 0;

        @include media-breakpoint(md, max) {
            padding: 0;
        }

        .top-row {
            display: flex;
            justify-content: space-between;

            .ant-tabs-nav-operations {
                display: none;
            }

            .ant-btn {
                background: #000;
                color: #fff;
                border: none;
                border-radius: 4px;
                padding: 10px 20px;
                height: 40px;
            }
        }

        .middle-row {
            // border-bottom: 1px solid #fff;
            padding-bottom: 20px;

            .ant-select {
                font-size: 14px;

                .ant-select-selector {
                    border-radius: 4px;
                }

                .ant-select-arrow {
                    color: $color-grey-dark;
                    font-size: 14px;
                }

                .ant-select-selector {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    .ant-select-selection-placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }

                @include media-breakpoint(md, max) {
                    &.sorter {
                        .ant-select-selector {
                            color: #fff;
                        }
                    }
                }
            }

            .ant-input-affix-wrapper {
                color: $color-grey-dark;
                background-color: $color-grey-light;

                .ant-input {
                    color: $color-grey-dark;
                    background-color: $color-grey-light;

                    &::placeholder {
                        color: $color-grey-dark;
                        font-size: 14px;
                    }
                }
            }
        }

        .ant-tabs-top {
            .ant-tabs-nav::before {
                border-bottom: none;
            }
        }

        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                color: #fff
            }
        }

        .ant-tabs-ink-bar {
            background: $color-accent1;
            color: $color-button-text;
        }
    }
}

.ant-modal-mask {
    // pointer-events: none;
}

.ant-modal-wrap {
    pointer-events: none !important;
    z-index: 9999 !important;
}

.compare-modal-wrapper {
    padding-left: 8%;

    .ant-modal {
        vertical-align: bottom;
        pointer-events: all;

        .ant-modal-close-x {
            line-height: 30px;
            width: 30px;
            height: 30px;
            color: $color-grey-dark;
        }
    }

    .ant-modal-content,
    .ant-modal-body {
        border-radius: 10px;
    }
}

.custom-loader-wrapper {
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;

    button {
        background-color: var(--color-primary-dark2);
        border-color: var(--color-primary-dark2);
    }

    button span {
        margin-left: 0px !important;
        color: orange;
    }
}

.grayFilter g g {
    stroke: #444444;
}

@media only screen and (max-width: 768px) {
    .hide {
        display: none;
    }
}

.tileBorderStyling {
    height: 73.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ant-empty-description {
    color: white !important;
}

.selectedTabUnderline {
    border-bottom: 2px solid var(--color-accent1);
    padding-bottom: 5px;
}

.no-display {
    visibility: hidden;
    height: 0px;
}

.share-modal-header {
    font-size: 19px;
    font-family: var(--seagull-heading-font);
    color: white;
    padding-right: 20px;
}

.ant-modal-body {
    background-color: var(--color-primary-dark);
}

.communitySignupText {
    font-family: 'Work Sans';
    color: white;
}

.signupButton {
    background-color: #f67d28;
    border: #f67d28;
    border-radius: 6px;
    color: white;
    height: 50px;
    padding: 0 40px;
    font-weight: 500;
    font-size: 18px;    
    font-family: var(--seagull-heading-font);
    margin-top: 15px;
    width: 275px;
  }

  button:disabled {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border: #000 !important;
    color: white !important;
}