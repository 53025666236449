@import "../../assets/mixins.scss";
@import "../../assets/variables.scss";

.dataset-message-wrapper {
    width: 100%;
    @include media-breakpoint(md, max) {
        margin-bottom: 20px;
    }

    .dataset-message {
        color: $color-white;
        background-color: $color-accent1;
        padding: 5px 20px;
        font-size: 13px;
        display: flex;
        align-items: center;

        .anticon-message {
            margin-right: 7px;
        }

        a {
            color: $color-white;
            text-decoration: underline;
        }

        span {
            &:last-of-type {
                padding: 0 5px;
            }
        }
    }
}